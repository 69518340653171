import { Router } from '@angular/router';
import { CommonService } from './../../services/common.service';
import { ToastMessagesService } from './../../services/toast-messages.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  email = "";

  constructor(private toastMessagesService: ToastMessagesService, private commonService: CommonService, private router: Router) { }

  async ngOnInit(): Promise<void> {
    if (!navigator.onLine) {
      this.toastMessagesService.toastErrorMessage("Δεν υπάρχει σύνδεση στο διαδίκτυο.");
      await this.router.navigateByUrl('/');
    }
  }

  /**
   * Description: sends put request with the submitted email value in order to 
   * retrieve the login data
   */
  async putEmail() {
    if (!this.email) {
      this.toastMessagesService.toastErrorMessage("Παρακαλώ προσθέστε ένα έγκυρο email.")
    } else {

      let body = {
        email: this.email,
        admin_request: 0
      }

      try {
        await this.commonService.putRequest(`user/forgot`, body);
        this.toastMessagesService.toastSuccessMessage("Σας στάλθηκε email με οδηγίες για την ανάκτηση του κωδικού πρόσβασης.")
      } catch (error) {
        this.toastMessagesService.toastErrorMessage(error.error.message)
      }
    }
  }
}
