<h1 mat-dialog-title><div class="header">
  <span style="font-size: larger;">Σύνδεση χρήστη</span>
  <mat-icon  (click)="closeDialog()" style="cursor: pointer;">close</mat-icon>
</div></h1>

<div class="container" mat-dialog-content>
</div>
<div fxLayout="column" style="height: 100%;width: 100%">
    <div fxLayout="column" fxLayoutAlign="center center center" style="width: 100%; height: 100%;">
      <div fxLayout="row" fxLayoutAlign="center">
      </div>
      <div fxLayout="row" fxLayoutAlign="space-between" style="width: 100%; margin-top: 20px;">
        <div fxLayout="column" fxFlex="10">
          <div class="info-color" style="margin-top: 20px"><mat-icon>person</mat-icon></div>
          <div class="info-color" style="margin-top: 20px"><mat-icon>vpn_key</mat-icon></div>
        </div>
        <div fxLayout="column" fxFlex="90">
          <mat-form-field>
            <input matInput [(ngModel)]="username" placeholder="Όνομα χρήστη ή Email"/>
          </mat-form-field>
          <mat-form-field>
            <input matInput [type]="hide ? 'password' : 'text'" placeholder="Συνθηματικό" [(ngModel)]="password"/>
            <mat-icon style="cursor: pointer;" (click)="hide = !hide"
                      matSuffix>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
          </mat-form-field>
        </div>
      </div>
      <div fxLayout="row" style="margin-top: 20px;" fxLayoutAlign="center">
        <button mat-raised-button class="custom-button" [disabled]="!inputsValid()" (click)="userLogin()">Σύνδεση</button>
      </div>
      <div style="margin-top: 10px;">
        <a routerLink="" style="color: #008cba" (click)="forgotPassword()">Ξέχασα τον κωδικό πρόσβασής μου</a>
      </div>
    </div>
</div>
