
import { ActionCompletionDialogComponent } from './components/action-completion-dialog/action-completion-dialog.component';
import { ActivateAccountComponent } from './components/activate-account/activate-account.component'
import { AddReceiptDialogComponent } from './components/add-receipt-dialog/add-receipt-dialog.component'
import { AnnouncmentComponent } from './components/announcment/announcment.component'
import { AnonymousPrintComponent } from './components/anonymous-print/anonymous-print.component'
import { AppComponent } from './app.component'
import { AppRoutingModule } from './app-routing.module'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { BrowserModule } from '@angular/platform-browser'
import { ChangePasswordDialogComponent } from './components/change-password-dialog/change-password-dialog.component'
import { ConfirmPaymentDialogComponent } from './components/confirm-payment-dialog/confirm-payment-dialog.component';
import { ContactDialogComponent } from './components/contact-dialog/contact-dialog.component';
import { FilterPipe } from './filter.pipe'
import { FlexLayoutModule } from '@angular/flex-layout'
import { FooterComponent } from './components/footer/footer.component'
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component'
import { ForgotPasswordDialogComponent } from './components/forgot-password-dialog/forgot-password-dialog.component'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http'
import { HashLocationStrategy, LocationStrategy } from '@angular/common'
import { HeaderComponent } from './components/header/header.component';
import { InterceptService } from './interceptors/intercept.service'
import { InvoiceDialogComponent } from './components/invoice-dialog/invoice-dialog.component'
import { InvoiceDisplayDialogComponent } from './components/invoice-display-dialog/invoice-display-dialog.component'
import { LoginComponent } from './components/login/login.component'
import { LoginDialogComponent } from './components/login-dialog/login-dialog.component'
import { LogoutDialogComponent } from './components/logout-dialog/logout-dialog.component'
import { MainComponent } from './components/main/main.component'
import { MatBadgeModule} from '@angular/material/badge'
import { MatButtonModule } from '@angular/material/button'
import { MatButtonToggleModule } from '@angular/material/button-toggle'
import { MatCardModule } from '@angular/material/card'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { MatChipsModule } from '@angular/material/chips'
import { MatDialogModule } from '@angular/material/dialog'
import { MatDividerModule } from '@angular/material/divider'
import { MatExpansionModule } from '@angular/material/expansion'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatIconModule } from '@angular/material/icon'
import { MatInputModule } from '@angular/material/input'
import { MatListModule } from '@angular/material/list'
import { MatMenuModule } from '@angular/material/menu'
import { MatPaginatorModule } from '@angular/material/paginator'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { MatRadioModule } from '@angular/material/radio'
import { MatSelectModule } from '@angular/material/select'
import { MatSidenavModule } from '@angular/material/sidenav'
import { MatSnackBarModule } from '@angular/material/snack-bar'
import { MatSortModule } from '@angular/material/sort'
import { MatStepperModule } from '@angular/material/stepper'
import { MatTableModule } from '@angular/material/table'
import { MatTabsModule } from '@angular/material/tabs'
import { MatToolbarModule } from '@angular/material/toolbar'
import { MatTooltipModule } from '@angular/material/tooltip'
import { NavBarComponent } from './components/nav-bar/nav-bar.component'
import { NewPrintComponent } from './components/new-print/new-print.component'
import { NgModule } from '@angular/core'
import { PaymentService } from './services/payment.service';
import { PendingOrderDetailsComponent } from './components/pending-order-details/pending-order-details.component'
import { PrintDialogComponent } from './components/print-dialog/print-dialog.component'
import { PrintOrderDetailsComponent } from './components/print-order-details/print-order-details.component'
import { ProfileComponent } from './components/profile/profile.component'
import { ProfileDetailsCardComponent } from './components/profile-details-card/profile-details-card.component'
import { ProgressSpinnerDialogComponent } from './components/progress-spinner-dialog/progress-spinner-dialog.component'
import { ReadyOrderDetailsComponent } from './components/ready-order-details/ready-order-details.component'
import { RegisterComponent } from './components/register/register.component'
import { RegisterDialogComponent } from './components/register-dialog/register-dialog.component'
import { ServiceWorkerModule } from '@angular/service-worker'
import { TermsComponent } from './components/terms/terms.component'
import { environment } from '../environments/environment'

@NgModule({
  declarations: [
    ActionCompletionDialogComponent,
    ActivateAccountComponent,
    AddReceiptDialogComponent,
    AnnouncmentComponent,
    AnonymousPrintComponent,
    AppComponent,
    ChangePasswordDialogComponent,
    ConfirmPaymentDialogComponent,
    ContactDialogComponent,
    FilterPipe,
    FooterComponent,
    ForgotPasswordComponent,
    ForgotPasswordDialogComponent,
    HeaderComponent,
    InvoiceDialogComponent,
    InvoiceDisplayDialogComponent,
    LoginComponent,
    LoginDialogComponent,
    LogoutDialogComponent,
    MainComponent,
    NavBarComponent,
    NewPrintComponent,
    PendingOrderDetailsComponent,
    PrintDialogComponent,
    PrintOrderDetailsComponent,
    ProfileComponent,
    ProfileDetailsCardComponent,
    ProgressSpinnerDialogComponent,
    ReadyOrderDetailsComponent,
    RegisterComponent,
    RegisterDialogComponent,
    TermsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    HttpClientModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    FormsModule,
    MatButtonModule,
    MatToolbarModule,
    MatMenuModule,
    MatDialogModule,
    MatSelectModule,
    MatTabsModule,
    MatCardModule,
    MatDividerModule,
    MatStepperModule,
    ReactiveFormsModule,
    MatTooltipModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatProgressSpinnerModule,
    MatButtonToggleModule,
    MatChipsModule,
    MatRadioModule,
    MatSidenavModule,
    MatListModule,
    MatSnackBarModule,
    MatExpansionModule,
    MatBadgeModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    MatCheckboxModule
  ],
  entryComponents: [ProgressSpinnerDialogComponent],
  providers: [
    {
      provide: HTTP_INTERCEPTORS, useClass: InterceptService, multi: true
    },
    {
      provide: LocationStrategy, useClass: HashLocationStrategy
    },
    PaymentService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
