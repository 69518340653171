<div fxLayout="column" *ngIf="dataLoaded" style="width:100%; height: 100%">
  <div fxLayout="row" fxLayoutAlign="center center center">
    <div class="mat-title" style="margin-top: 100px">{{titleName !== undefined ? titleName : "Εκτυπώσεις"}}</div>
  </div>
  <mat-divider style="margin: 0.5%"></mat-divider>

  <div *ngIf="profile" class="center" style="margin-top: 1%;">
    <div fxLayoutAlign="start">
      <mat-icon class="icon-config badge-color" style="margin-right: 5px;">info</mat-icon>
      <span style="margin-top: 2px;">
        Εδώ μπορείτε να δείτε τις εκτυπώσεις σας.
      </span>
    </div>
  </div>

  <div *ngIf="printsTable.length > 0" fxLayoutAlign="end center center" [ngStyle.gt-sm]="{'margin-right': '40px'}" fxLayoutAlign.lt-md="center center center">
    <mat-form-field>
      <input matInput type="text" placeholder="Αναζήτηση" [(ngModel)]="search"/>
    </mat-form-field>
  </div>

  <div fxLayout="column" fxLayoutGap="5px" *ngIf="!(printsTable | filter: search).length">
    <div class="empty">
      Δεν βρέθηκαν εκτυπώσεις
    </div>
  </div>


  <div *ngIf="printsTable.length > 0; else elseBlock">

    <div fxLayout="row wrap" style="padding: 0px 20px 20px 20px">
      <mat-card *ngFor="let print of printsTable.slice().reverse() | filter: search;" class="mat-elevation-z5">

        <div fxLayout="row" fxLayoutAlign="start">
          <div fxLayout="column" fxLayoutGap="5px">
            <div>
              <table>
                <tr>
                  <td style="width: 200px;">
                    Αρ. εκτύπωσης:
                  </td>
                  <td>
                    {{print.order_id}}
                  </td>
                </tr>
                <tr>
                  <td>
                    Ημερομηνία δημιουργίας:
                  </td>
                  <td>
                    {{print.date_created}}
                  </td>
                </tr>
                <tr>
                  <td>
                    Τιμή:
                  </td>
                  <td>
                    {{print.total_price}}€
                  </td>
                </tr>
                <tr *ngIf="print.status_id === 3">
                  <td>
                    Ημερομηνία ολοκλήρωσης:
                  </td>
                  <td>
                    {{print.date_completed}}
                  </td>
                </tr>
                <tr>
                  <td>
                    Τρόπος Παράδοσης:
                  </td>
                  <td>
                    {{print.shipping_method_description}}
                  </td>
                </tr>
                <tr>
                  <td>
                    Τρόπος Πληρωμής:
                  </td>
                  <td>
                    {{print.payment_description}}
                  </td>
                </tr>
                <tr>
                  <td>
                    Εκτιμώμενη ημ. παράδοσης:
                  </td>
                  <td>
                    {{print.desired_completed_date}}
                  </td>
                </tr>
                <tr>
                  <td>
                    Κατάσταση εκτύπωσης:
                  </td>
                  <td>
                    <span style="font-weight: bold">{{print.status_description}}</span>
                  </td>
                </tr>
                <tr *ngIf="print.notes" fxHide.lt-sm>
                  <td style="vertical-align:top;">
                    Σχόλια:
                  </td>
                  <td>
                      <p>
                        {{print.notes}}
                      </p>
                  </td>
                </tr>
              </table>
              <div *ngIf="print.notes" fxHide.gt-xs>
                Σχόλια:
                <p>
                  {{print.notes}}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div fxLayout="row" fxLayoutAlign="end">
          <ng-container *ngIf="print.loading === undefined || !print.loading" >
            <mat-icon matTooltip="Δείτε τις επιλογές εκτύπωσης" *ngIf="!print.collapsed" style="cursor: pointer" (click)="openPrintOptions(print)">expand_more</mat-icon>
            <mat-icon matTooltip="κλείσιμο" *ngIf="print.collapsed" style="cursor: pointer" (click)="openPrintOptions(print)">expand_less</mat-icon>
          </ng-container>
          <mat-spinner *ngIf="print.loading !== undefined && print.loading" [diameter]="30" class="transparent spinner"></mat-spinner>
        </div>

        <mat-card-content *ngIf="print.collapsed">
          <div fxLayoutAlign="center" style="margin:10px"><h2><strong>Επιλογές εκτύπωσης</strong></h2></div>
          <div style="padding: 10px">
            <mat-divider></mat-divider>
          </div>
          <div>
            <table fxHide.lt-md style="width: 100%">
              <tr>
                <th *ngFor="let opt of print.columns">{{opt}}</th>
              </tr>
              <tr class="row" *ngFor="let details of print.details; let index = index">
                <td style="text-align: center;"> {{index + 1}}</td>
                <td style="text-align: center;"> {{details.file_name}}</td>
                <td style="text-align: center;"> {{details.file_pages}}</td>
                <td style="text-align: center;"> {{details.copies}}</td>
                <td style="text-align: center;"> {{details.page_orientation_description}}</td>
                <td style="text-align: center;"> {{details.page_side_description}}</td>
                <td style="text-align: center;" *ngFor="let property of details.properties"> {{property.description}}</td>
              </tr>
            </table>

            <div fxHide.gt-sm class="details" *ngFor="let opt of print.details; let index = index" style="margin: 10px">
              <div *ngIf="opt.file_id">

                <table width="100%" class="sm-table">
                  <tr>
                    <td>
                      #
                      {{index + 1}}
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 40%;">
                      Αρχείο:
                    </td>
                    <td>
                      {{opt.file_name}}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Σελίδες:
                    </td>
                    <td>
                      {{opt.file_pages}}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Αντίτυπα:
                    </td>
                    <td>
                      {{opt.copies}}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Προσανατολισμός σελίδας:
                    </td>
                    <td>
                      {{opt.page_orientation_description}}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Μορφοποίηση σελίδας:
                    </td>
                    <td>
                      {{opt.page_side_description}}
                    </td>
                  </tr>
                  <tr *ngFor="let property of opt.properties">
                    <td>
                      {{property.category_description}}:
                    </td>
                    <td>
                      {{property.description}}
                    </td>
                  </tr>
                </table>

              </div>
            </div>

          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
 </div>
