<div fxLayout="column" style="height: 500px" [ngStyle.gt-xs]="{'width':' 450px'}">
  <div fxLayout="row" fxLayoutAlign="end center">
    <mat-icon (click)="closeDialog()" style="cursor: pointer;">clear</mat-icon>
  </div>
  <div fxLayout="row">
    <button mat-raised-button type="button" (click)="fileInput.click()">
      <mat-icon>photo_library</mat-icon>
      <input #fileInput style="display:none;margin: 10px" type="file" (change)="onFileSelected($event)"/>
    </button>
    <button (click)="notDisabled()" style="margin-left: 10px" mat-button>
      Ανέβασμα
    </button>
  </div>
  <div fxLayout="row" class="mat-subheading-2" fxLayoutAlign="center center center">
    Επιλογές εκτύπωσης
  </div>

  <div fxLayout="row wrap" fxLayout.xs="column" *ngIf="optionsLoaded">
<!--    <div *ngFor="let category of printingOptions;let i = index">-->
      <!--      <div *ngIf="category.options.length > 0" >-->
      <!--        <mat-form-field appearance="fill" style="margin: 5px">-->
      <!--          <mat-label>{{category.categoryDescription}}</mat-label>-->
      <!--          <mat-select [(ngModel)]="selectedOption[i]" required [disabled]="isDisabled">-->
      <!--            <mat-option  *ngFor="let option of category.options" [value]="option">{{option.description}}</mat-option>-->
      <!--          </mat-select>-->
      <!--        </mat-form-field>-->
      <!--      </div>-->
      <!--      <div *ngIf="category.options.length === 0">-->
      <!--        <mat-form-field appearance="fill" style="margin: 5px">-->
      <!--          <mat-label>{{category.categoryDescription}}</mat-label>-->
      <!--          <input [placeholder]="category.categoryDescription" type="number" [min]="0" [(ngModel)]="category.value"-->
      <!--                 matInput required [disabled]="isDisabled"/>-->
      <!--        </mat-form-field>-->
      <!--      </div>-->
      <!--    </div>-->

        <mat-form-field appearance="fill" style="margin: 5px">
          <mat-label>Χρώμα σελίδας</mat-label>
          <mat-select [(value)]="color" [disabled]="isDisabled">
            <mat-option [value]="printingOptions[0].options[0]">Έγχρωμη</mat-option>
            <mat-option [value]="printingOptions[0].options[1]">Μονόχρωμη</mat-option>
          </mat-select>
        </mat-form-field>


    <mat-form-field appearance="fill" style="margin: 5px">
      <mat-label>Βιβλιοδεσία</mat-label>
      <mat-select [(value)]="binding" [disabled]="isDisabled">
        <mat-option [value]="printingOptions[1].options[0]">Σπιράλ</mat-option>
        <mat-option [value]="printingOptions[1].options[1]">Συρραφή</mat-option>
        <mat-option [value]="printingOptions[1].options[2]">Booklet</mat-option>
      </mat-select>
    </mat-form-field>


    <mat-form-field appearance="fill" style="margin: 5px">
      <mat-label>Προσανατολισμός σελίδας</mat-label>
      <mat-select [(value)]="direction" [disabled]="isDisabled">
        <mat-option [value]="printingOptions[2].options[0]">Κάθετα</mat-option>
        <mat-option [value]="printingOptions[2].options[1]">Οριζόντια</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill" style="margin: 5px">
      <mat-label>Τύπος χαρτιού</mat-label>
      <mat-select [(value)]="paper" [disabled]="isDisabled">
        <mat-option [value]="printingOptions[3].options[0]">Α4</mat-option>
        <mat-option [value]="printingOptions[3].options[1]">Α5</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill" style="margin: 5px">
      <mat-label>Μορφοποίηση σελίδας</mat-label>
      <mat-select [(value)]="page" [disabled]="isDisabled">
        <mat-option [value]="printingOptions[4].options[0]">Μονή σελίδα</mat-option>
        <mat-option [value]="printingOptions[4].options[1]">Διπλή σελίδα</mat-option>
      </mat-select>
    </mat-form-field>

      <mat-form-field appearance="fill" style="margin: 5px">
        <mat-label>Επιλογές αποστολής</mat-label>
        <mat-select [(value)]="sendCopy" [disabled]="isDisabled">
          <mat-option [value]="printingOptions[5].options[0]">Αποστολή στο σπίτι</mat-option>
          <mat-option [value]="printingOptions[5].options[1]">Παραλαβή από κατάστημα</mat-option>
        </mat-select>
      </mat-form-field>

        <mat-form-field appearance="fill" style="margin: 5px">
          <mat-label>Αντίτυπα</mat-label>
          <input [(ngModel)]="copies"  type="number" [min]="0" matInput [disabled]="isDisabled"/>
        </mat-form-field>


  </div>
  <div fxLayout="row" fxLayoutAlign="space-between center" fxLayout.xs="column">
    <div style="margin: 10px"> Σύνολο: {{totalPrice}}</div>
        <button style="margin-top: 10px" mat-raised-button color="primary"
                [ngStyle.xs]="{'margin-bottom':'10px'}" (click)="printOrder()">Print
        </button>
      </div>
    </div>
