import {Injectable} from '@angular/core';
import {CommonService} from './common.service';

@Injectable({
  providedIn: 'root'
})
export class FileUploadService {
  // isDisabled: boolean;
  selectedFiles: Array<File> = [];

  constructor(private commonService: CommonService) {
  }

  async uploadFile(print, files): Promise<any> {
    try {
      const fd = new FormData();
      this.selectedFiles = files as Array<File>;
      for (const img of this.selectedFiles) {
        fd.append('file', img, img.name);
      }
      fd.append('print', JSON.stringify(print));
      return await this.commonService.postFilesRequest(`request`, fd);
      // return this.isDisabled;
    } catch (e) {
      throw e;
    }
  }

  // async getFilePages(file) {
  //   console.log(file);
  //   // this.pageSizes = [];
  //   let count = 0;
  //   const reader = new FileReader();
  //   reader.readAsBinaryString(file);
  //   reader.onload = () => {
  //     if (typeof reader.result === 'string') {
  //       console.log('in');
  //       count =  reader.result.match(/\/Type[\s]*\/Page[^s]/g).length;
  //       console.log(count);
  //       // this.pageSizes.push(count);
  //     }
  //   };
  //   return count;
  // }
}
