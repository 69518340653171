import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router } from '@angular/router';
import { UserService } from './services/users/user.service';

@Injectable({
  providedIn: 'root'
})

export class AccessGuard implements CanActivate {

  constructor(private userService: UserService, private router: Router) {}

  async canActivate(route: ActivatedRouteSnapshot)  {

    const requiresLogin = route.data.requiresLogin || false;

    if (requiresLogin) {
     
      if (!navigator.onLine) {
        return false;
      }

      try {
        const user = await this.userService.validateUser();
 
        if (user) {
          return true;
        } else {
          this.router.navigateByUrl('/');
        }

      } catch (error) {
        this.router.navigateByUrl('/');
      }
    }
    return true;
  }
  
}
