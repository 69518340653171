import { ProgressSpinnerDialogService } from './../../services/progress-spinner-dialog.service';
import { CommonService } from './../../services/common.service';
import { TermsComponent } from './../terms/terms.component';
import { UserService } from './../../services/users/user.service';
import {Component, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {PrintDialogComponent} from '../print-dialog/print-dialog.component';
import {PrintService} from '../../services/print.service';
import {PrintOrderDetails} from '../../interfaces/printOrder';
import {DataService} from '../../services/data.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {
  user: any;
  orders: PrintOrderDetails[] = [];
  ready: PrintOrderDetails[] = [];
  pending: PrintOrderDetails[] = [];
  data: any;
  ordersLoaded: boolean;
  displayVersionUpdates: boolean;

  navigationPaths = [ {
    path: 'new-print',
    label: 'Νέα εκτύπωση',
    icon: '',
    content: 'Ξεκινήστε μια νέα εκτύπωση'
  },
    {
      path: 'ready-order',
      label: 'Οι εκτυπώσεις μου',
      icon: '',
      content: 'Δείτε το ιστορικό των εκτυπώσεών σας'
    },
    {
      path: 'pending-order',
      label: 'Εκκρεμείς εκτυπώσεις',
      icon: '',
      content: 'Δείτε την πορεία των εκτυπώσεών σας'
    },
    {
      path: 'profile',
      label: 'Προφίλ',
      icon: '',
      content: 'Διαχειριστείτε τον λογαριασμό σας'
    }];
  contactData: any;

  constructor(public dialog: MatDialog,
              private userService: UserService,
              public dataService: DataService,
              private commonService: CommonService,
              private progressSpinnerDialogService: ProgressSpinnerDialogService) {
    this.ordersLoaded = false;
    this.displayVersionUpdates = false;
  }

  async ngOnInit() {
    this.dialog.closeAll();
    try {
      let user = await this.userService.getLoggedUser();

      if (!user.has_accepted_terms) {
        this.displayTerms();
      }
      this.contactData = await this.commonService.getRequest('communication');

      // localStorage.removeItem('model');
      if (JSON.parse(localStorage.getItem('model')) !== null) {
        this.data = JSON.parse(localStorage.getItem('model'));
        this.dataService.orders = JSON.parse(localStorage.getItem('model'));
      } else {
        this.data = [];
        this.dataService.orders = [];
      }
      // // this.orders = await this.printService.getPrintingOrders();
      // for (const print of this.orders) {
      //   if (print.status_id === 3) {
      //     this.ready.push(print);
      //   } else {
      //     this.pending.push(print);
      //   }
      // }
      this.ordersLoaded = true;
    } catch (e) {
      console.log(e);
    }
  }

  openPrintDialog() {
    const myDialog = this.dialog.open(PrintDialogComponent, {});
    myDialog.afterClosed().subscribe(data => {
      if (data) {
        this.data.push(data);
      }
    });
  }

  displayTerms() {
    const dialog = this.dialog.open(TermsComponent, { data: {logout: true}, disableClose: true });

    dialog.afterClosed().subscribe(result => {
      console.log(result);
      if (result.logout) {
        this.userService.userLogout();
      } else if (result.accept) {
        this.acceptTerms();
      }
    });
  }

  async acceptTerms() {

    try {
      this.progressSpinnerDialogService.show();
      let result = await this.userService.updateUserInfo({has_accepted_terms: true});
      this.progressSpinnerDialogService.end();
    } catch (error) {
      this.progressSpinnerDialogService.end();
    }

  }

}
