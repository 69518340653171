import { ProfileDetailsCardComponent } from './../profile-details-card/profile-details-card.component';
import { ProgressSpinnerDialogService } from 'src/app/services/progress-spinner-dialog.service';
import {Component, OnInit, ViewChild} from '@angular/core';
import {UserService} from '../../services/users/user.service';
import { TermsComponent } from '../terms/terms.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  mode: any;
  user: any;
  dataLoaded: boolean;

  @ViewChild('child') child: ProfileDetailsCardComponent;
  @ViewChild('small_child') small_child: ProfileDetailsCardComponent;

  constructor(private userService: UserService, 
              private progressSpinnerDialogService: ProgressSpinnerDialogService,
              private dialog: MatDialog) {
    this.dataLoaded = false;
  }

  async ngOnInit() {
    try {
      this.progressSpinnerDialogService.show();
      this.user = this.userService.checkLoggedUser();
      this.progressSpinnerDialogService.end();
      this.dataLoaded = true;
    } catch (error) {
      this.progressSpinnerDialogService.end();
    }

  }

  showDetails(m) {
 
    if (m === 2) {
      this.child.loadPrints();
      this.small_child.loadPrints();
    }

    if (m === 4) {
      this.child.loadReceiptData();
      this.small_child.loadReceiptData();
    }

    if (m === 5) {
      this.openTerms()
    } else {
      this.mode = m;
    }

  }

  changeTab(event) {
    if (event.index === 0) {
      this.mode = 1
    } else if (event.index === 1) {
      this.child.loadPrints();
      this.small_child.loadPrints();
      this.mode = 2
    } else if (event.index === 2) {
      this.child.loadReceiptData();
      this.small_child.loadReceiptData();
      this.mode = 4

    } else if (event.index === 5) {
      this.openTerms()
    }
  }

  async openTerms() {
    const dialog = this.dialog.open(TermsComponent, 
      { 
        data: {preview: true}
      }
    );

    dialog.afterClosed().subscribe(result => {
       
    });
  }
  
}

