import { CommonService } from 'src/app/services/common.service';
import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-announcment',
  templateUrl: './announcment.component.html',
  styleUrls: ['./announcment.component.scss']
})
export class AnnouncmentComponent implements OnInit {

  dataLoaded: boolean;
  announcements: any;
  announcementsTable = [];
  search = '';
  columns: any;

  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>();


  constructor(private commonService: CommonService) {
    this.dataLoaded = false;
   }

  async ngOnInit() {
    await this.initData();
  }

  async initData() {
    this.announcementsTable = await this.commonService.getRequest('announcement')
    let counter = await this.commonService.getRequest('user/new-announcements')

    if (counter > 0) {
      for (var i = this.announcementsTable.length - 1; i >= counter; i--) {
        this.announcementsTable[i].is_new = true;
      }
    }

    await this.commonService.getRequest('user/update-announcements-date')
    this.dataSource.data = this.announcementsTable;
    this.dataLoaded = true;
  }

  async searchPrint() {
    try {
      if (this.search === '') {
        this.announcementsTable = this.announcementsTable;
      } else {
        this.announcementsTable = this.announcementsTable.filter(item => item.prints.id === +this.search);
      }
    } catch (e) {
      console.log(e);
    }
  }

  async getPrintOptions(announcement: any) {
    try {
      let options = ['Α/Α', 'Τίτλος', 'Ημερομηνία'];
      announcement.details = []

      announcement.columns = options
    } catch (error) {
      console.log(error);
    }
  }

  async openContext(announcement: any) {
    announcement.collapsed = !announcement.collapsed
  }

   
}
