import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component'
import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'
import { LoginComponent } from './components/login/login.component'
import { NavBarComponent } from './components/nav-bar/nav-bar.component'
import { MainComponent } from './components/main/main.component'
import { ProfileComponent } from './components/profile/profile.component'
import { NewPrintComponent } from './components/new-print/new-print.component'
import { PrintOrderDetailsComponent } from './components/print-order-details/print-order-details.component'
import { PendingOrderDetailsComponent } from './components/pending-order-details/pending-order-details.component'
import { ReadyOrderDetailsComponent } from './components/ready-order-details/ready-order-details.component'
import { RegisterComponent } from './components/register/register.component'
import { AnonymousPrintComponent } from './components/anonymous-print/anonymous-print.component'
import { ActivateAccountComponent } from './components/activate-account/activate-account.component'
import { AccessGuard } from './access.guard'
import { AnnouncmentComponent } from './components/announcment/announcment.component'

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'anonymous-print',
  },
  {
    component: LoginComponent,
    path: 'login',
  },
  {
    component: RegisterComponent,
    path: 'register',
  },
  {
    component: AnonymousPrintComponent,
    path: 'anonymous-print',
  },
  {
    component: ActivateAccountComponent,
    path: 'activate-account/:id',
  },
  {
    component: ForgotPasswordComponent,
    path: 'forgot-password',
  },
  {
    path: 'home',
    component: NavBarComponent,
    children: [
      {
        // add new print as main
        component: MainComponent,
        // component: NewPrintComponent,
        path: '',
        data: { requiresLogin: true },
        canActivate: [AccessGuard],
      },
      {
        // add new print as main
        component: MainComponent,
        // component: NewPrintComponent,
        path: 'main',
        data: { requiresLogin: true },
        canActivate: [AccessGuard],
      },
      {
        component: ProfileComponent,
        path: 'profile',
        data: { requiresLogin: true },
        canActivate: [AccessGuard],
      },
      {
        component: NewPrintComponent,
        path: 'new-print',
        data: { requiresLogin: true },
        canActivate: [AccessGuard],
      },
      {
        component: ReadyOrderDetailsComponent,
        path: 'ready-order',
        data: { requiresLogin: true },
        canActivate: [AccessGuard],
      },
      {
        component: PendingOrderDetailsComponent,
        path: 'pending-order',
        data: { requiresLogin: true },
        canActivate: [AccessGuard],
      },
      {
        component: AnnouncmentComponent,
        path: 'announcement',
        data: { requiresLogin: true },
        canActivate: [AccessGuard],
      },
    ],
  },
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
