<div fxLayout="column" style="margin-top: 100px" fxFlex >
  <div fxLayout="row wrap" fxFlex fxLayoutAlign="center center center">

    <div *ngFor="let item of navigationPaths" fxLayoutAlign="center center center">
      <mat-card class="card mat-elevation-z4"
        [ngStyle.xs]="{'width':'300px', 'height':'200px'}"
        [ngStyle.md]="{'width':'400px', 'height':'200px'}"
        [ngStyle.lg]="{'width':'550px', 'height':'200px'}"
        [ngStyle.gt-lg]="{'width':'700px', 'height':'200px'}"
       >
        <mat-card-title class="title" fxLayoutAlign="center center center">
          {{item.label}}
        </mat-card-title>
        <mat-card-content fxLayoutAlign="center center center">
          {{item.content}}
        </mat-card-content>
        <mat-card-actions style="margin-top: 50px;">
          <a routerLinkActive="active" routerLink="/home/{{item.path}}"><button class="btn" mat-raised-button>Πλοήγηση</button></a>
        </mat-card-actions>
      </mat-card>
    </div>
  </div>
</div>

<div *ngIf="displayVersionUpdates" style="position: absolute; bottom: 2%; right: 2%; z-index: 999;">
  <mat-card style="height: 550px; width: 300px; overflow-y: auto;">
    <mat-card-title>
      <span fxFlex> Version 1.0</span>
      <mat-icon style="cursor: pointer" (click)="displayVersionUpdates=false">close</mat-icon>
    </mat-card-title>
    <mat-card-content>
      1. Login: Εάν κάποιο από τα πεδία δεν είναι συμπληρωμένο δε μπορείς να πατήσεις σύνδεση <br>
      2. Εγγραφή χρήστη: <br>
        2.1 Απενεργοποίηση κουμπιών εάν κάποιο από τα πεδία δεν έχει συμπληρωθεί <br>
        2.2 Προσθήκη κουμπιού πίσω στο login <br>
      3. Υλοποίηση παραγγελίας χωρίς εγγραφή χρήστη <br>
      4. Αρχική: <br>
        4.1 Βελτίωση εμφάνισης σε mobile view <br>
      5. Οι εκτυπώσεις μου: <br>
        5.1 Υλοποίηση αναζήτησης <br>
      6. Προφίλ μου: <br>
        6.1 Διόρθωση UI στην εμφάνιση των πληροφοριών του χρήστη(και στο mobile view) <br>
        6.2 Στην επεξεργασία πληροφοριών εάν σβήσεις το value από ένα πεδίο δε μπορείς να πατήσεις αποθήκευση <br>
        6.3 Στην Αλλαγή Κωδικού Πρόσβασης δε μπορείς να επιλέξεις αποθήκευση εάν κάποιο πεδίο δεν έχει συμπληρωθεί <br>
      7. Νέα εκτύπωση: <br>
        7.1 Δε μπορείς να προχωρήσεις στο επόμενο βήμα εάν δεν επιλέξεις αρχείο <br>
        7.2 Διόρθωση συνόλου και ονομάτων αρχείων στο mobile view <br>
        7.3 Όλες οι τιμές (και του κάθε αρχείου και του τελικού συνόλου) είναι μέχρι 2 δεκαδικά ψήφια <br>
        7.4 Αλλαγή στον τρόπο πληρωμής(δημιουργήθηκε πίνακας στη βάση γιατί το είχαμε hardcoded) <br>
      8. Ενεργοποίηση λογαριασμού χρήστη μετά την εγγραφή του <br>
      9. Εμφάνιση μηνυμάτων σε snackBars (υλοποιήθηκε κοινό για όλες τις ειδοποιήσεις προς το χρήστη)
    </mat-card-content>
  </mat-card>
</div>
