import { CommonService } from './../../services/common.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  data;
  
  constructor(private commonService: CommonService) { }

  async ngOnInit() {
    this.data = await this.commonService.getRequest('communication')
  }

}
