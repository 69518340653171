
<div class="main-container" style="padding-top: 10px;">
    <div class="logo">
        <img src="../../../assets/pr-logo.png">
    </div>
    <div *ngIf="data" class="items">

        <div class="item">
            <div>
                <mat-icon>home</mat-icon>
            </div>
            <div>
                {{data.address}}, {{data.city}} {{data.postal_code}}
            </div>
        </div>

        <div class="item">
            <div>
                <mat-icon>phone</mat-icon>
            </div>
            <div>
                {{data.phone_number}}
            </div>
        </div>

        <div class="item">
            <div>
                <mat-icon>email</mat-icon>
            </div>
            <div>
                {{data.email}}
            </div>
        </div>
    </div>
</div>
