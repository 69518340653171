<div fxLayout="column" style="height: 100%; width: 100%" fxLayoutAlign="center center center">
  <div fxLayout="column" *ngIf="!stepTwo" [ngStyle.lt-sm]="{'width':'90%'}">
    <div fxLayout="row" fxLayoutAlign="center" class="mat-subheading-2">
      Εγγραφή νέου χρήστη
    </div>
    <div fxLayout="row">
      <div fxLayout="column" fxFlex="40" style="margin-top: 10px" fxLayoutGap="60px">
        <div style="margin-top: 20px">Όνομα χρήστη:</div>
        <div style="margin-top: 40px">Συνθηματικό:</div>
      </div>
      <div fxLayout="column" fxFlex="60">
        <mat-form-field appearance="outline">
          <input matInput [(ngModel)]="username" required/>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <input matInput [type]="hide ? 'password' : 'text'" [(ngModel)]="password" required/>
          <mat-icon style="cursor: pointer;" (click)="hide = !hide"
                    matSuffix>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
        </mat-form-field>
      </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <button mat-raised-button [routerLink]="['/login']" routerLinkActive="active">Πίσω</button>
      <button mat-raised-button [disabled]="!isInputValid()" (click)="getNextStep()">Επόμενο</button>
    </div>
  </div>
  <div fxLayout="column" *ngIf="stepTwo" style="width: 50%" [ngStyle.lt-sm]="{'width':'90%'}">
    <div fxLayout="row" fxLayoutAlign="center" class="mat-subheading-2">
      Στοιχεία επικοινωνίας
    </div>
    <div fxLayout="row">
      <div fxLayout="column" fxFlex="50" fxLayoutGap="60px" style="margin-top: 10px">
        <div style="margin-top: 20px">Όνομα:</div>
        <div style="margin-top: 40px">Επώνυμο:</div>
        <div style="margin-top: 40px">Email:</div>
        <div style="margin-top: 40px">Τηλ. επικοινωνίας:</div>
        <div style="margin-top: 40px">Διεύθυνση:</div>
        <div style="margin-top: 40px">ΤΚ:</div>
      </div>
      <form fxLayout="column" fxFlex="50" [formGroup]="userInfoForm">
        <mat-form-field appearance="outline">
          <input matInput formControlName="firstName"/>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <input matInput formControlName="lastName"/>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <input matInput formControlName="email"/>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <input matInput formControlName="phoneNumber"/>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <input matInput formControlName="address"/>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <input matInput formControlName="postalCode"/>
        </mat-form-field>
      </form>
    </div>
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <button mat-raised-button (click)="getPreviousStep()">Προηγούμενο</button>
      <button mat-raised-button [disabled] ="!isFormInvalid()" (click)="registerUser()">Εγγραφή</button>
    </div>

  </div>
</div>



