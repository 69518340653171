import { Component, OnInit } from '@angular/core';
import { CommonService } from './../../services/common.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  data;

  constructor(private commonService: CommonService) { }

  async ngOnInit(): Promise<void> {
    this.data = await this.commonService.getRequest('communication');
  }

}
