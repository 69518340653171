import {Injectable} from '@angular/core';
import {CommonService} from './common.service';
import {PrintingOption} from '../interfaces/printingOption';
import {PrintOrderDetails} from '../interfaces/printOrder';
import {PaymentMethods} from '../interfaces/paymentMethods';
import {ShippingOption} from '../interfaces/shippingOption';

@Injectable({
  providedIn: 'root'
})
export class PrintService {
  // printingOptions: PrintingOption[] = [{
  //   categoryId: 1,
  //   categoryDescription: 'Χρώμα Σελίδας',
  //   options: [{
  //     id: 1,
  //     description: 'Εγχρωμη',
  //     price: 0.25,
  //   },
  //     {
  //       id: 2,
  //       description: 'Μονόχρωμη',
  //       price: 0.10,
  //     }]
  // },
  //   {
  //     categoryId: 2,
  //     categoryDescription: 'Βιβλιοδεσία',
  //     options: [{
  //       id: 3,
  //       description: 'Σπιραλ',
  //       price: 0.25,
  //     },
  //       {
  //         id: 4,
  //         description: 'Συρραφή',
  //         price: 0.10,
  //       },
  //       {
  //         id: 5,
  //         description: 'Booklet',
  //         price: 0.10,
  //       }]
  //   },
  //   {
  //     categoryId: 3,
  //     categoryDescription: 'Προσανατολισμός σελίδας',
  //     options: [{
  //       id: 6,
  //       description: 'Κάθετα',
  //       price: 0.25,
  //     },
  //       {
  //         id: 7,
  //         description: 'Οριζόντια',
  //         price: 0.10,
  //       }]
  //   },
  //   {
  //     categoryId: 4,
  //     categoryDescription: 'Τύπος χαρτιού',
  //     options: [{
  //       id: 8,
  //       description: 'Α4',
  //       price: 0.25,
  //     },
  //       {
  //         id: 9,
  //         description: 'Α5',
  //         price: 0.10,
  //       }]
  //   },
  //   {
  //     categoryId: 5,
  //     categoryDescription: 'Μορφοποίηση σελίδας',
  //     options: [{
  //       id: 10,
  //       description: 'Μονή σελίδα',
  //       price: 0.25,
  //     },
  //       {
  //         id: 11,
  //         description: 'Διπλή σελίδα',
  //         price: 0.10,
  //       }]
  //   },
  //   {
  //     categoryId: 15,
  //     categoryDescription: 'Αντίτυπα',
  //     options: [],
  //     price: 0.5,
  //     value: 0,
  //   },
    // {
    //   categoryId: 6,
    //   categoryDescription: 'Επιλογές αποστολής',
    //   options: [{
    //     id: 12,
    //     description: 'Παραλαβή από κατάστημα',
    //     price: 0.25,
    //   },
    //     {
    //       id: 13,
    //       description: 'Αποστολή στο σπίτι',
    //       price: 0.10,
    //     }]
    // },
  // ];

  // shippingOptions: ShippingOption[] = [
  //   {
  //     id: 1,
  //     option: 'Παραλαβή από κατάστημα',
  //     value: 1,
  //     price: 0.0
  //   },
  //   {
  //     id: 2,
  //     option: 'Αποστολή στο σπίτι',
  //     value: 0,
  //     price: 1.5
  //   },
  // ];

  printingOrders: PrintOrderDetails[] = [
    // {
    //   id: 1,
    //   date: '1 Απριλίου 2020',
    //   print: [
    //     {
    //       categoryId: 1,
    //       categoryDescription: 'Χρώμα Σελίδας',
    //       options: [{
    //         id: 1,
    //         description: 'Εγχρωμη',
    //         price: 0.25,
    //       },
    //       ],
    //     },
    //     {
    //       categoryId: 2,
    //       categoryDescription: 'Βιβλιοδεσία',
    //       options: [{
    //         id: 3,
    //         description: 'Σπιραλ',
    //         price: 0.25,
    //       },
    //       ],
    //     },
    //     {
    //       categoryId: 3,
    //       categoryDescription: 'Προσανατολισμός σελίδας',
    //       options: [{
    //         id: 6,
    //         description: 'Κάθετα',
    //         price: 0.25,
    //       },
    //       ],
    //     },
    //     {
    //       categoryId: 4,
    //       categoryDescription: 'Τύπος χαρτιού',
    //       options: [{
    //         id: 8,
    //         description: 'Α4',
    //         price: 0.25,
    //       },
    //       ],
    //     },
    //     {
    //       categoryId: 5,
    //       categoryDescription: 'Μορφοποίηση σελίδας',
    //       options: [{
    //         id: 10,
    //         description: 'Μονή σελίδα',
    //         price: 0.25,
    //       },
    //       ],
    //     },
    //     {
    //       categoryId: 15,
    //       categoryDescription: 'Αντίτυπα',
    //       options: [],
    //       price: 0.5,
    //       value: 6,
    //     },
    //     {
    //       categoryId: 6,
    //       categoryDescription: 'Επιλογές αποστολής',
    //       options: [{
    //         id: 12,
    //         description: 'Παραλαβή από κατάστημα',
    //         price: 0.25,
    //       },
    //       ],
    //     },
    //   ],
    //   price: 2,
    //   state: 'Ολοκληρωμένη',
    //   user_id: 1,
    // },
    // {
    //   id: 2,
    //   date: '3 Απριλίου 2020',
    //   print: [
    //     {
    //       categoryId: 1,
    //       categoryDescription: 'Χρώμα Σελίδας',
    //       options: [{
    //         id: 2,
    //         description: 'Μονόχρωμη',
    //         price: 0.10,
    //       },
    //       ],
    //     },
    //     {
    //       categoryId: 2,
    //       categoryDescription: 'Βιβλιοδεσία',
    //       options: [{
    //         id: 3,
    //         description: 'Σπιραλ',
    //         price: 0.25,
    //       },
    //       ],
    //     },
    //     {
    //       categoryId: 3,
    //       categoryDescription: 'Προσανατολισμός σελίδας',
    //       options: [{
    //         id: 7,
    //         description: 'Οριζόντια',
    //         price: 0.20,
    //       },
    //       ],
    //     },
    //     {
    //       categoryId: 4,
    //       categoryDescription: 'Τύπος χαρτιού',
    //       options: [{
    //         id: 8,
    //         description: 'Α4',
    //         price: 0.25,
    //       },
    //       ],
    //     },
    //     {
    //       categoryId: 5,
    //       categoryDescription: 'Μορφοποίηση σελίδας',
    //       options: [{
    //         id: 10,
    //         description: 'Μονή σελίδα',
    //         price: 0.25,
    //       },
    //       ],
    //     },
    //     {
    //       categoryId: 15,
    //       categoryDescription: 'Αντίτυπα',
    //       options: [],
    //       price: 0.5,
    //       value: 0,
    //     },
    //     {
    //       categoryId: 6,
    //       categoryDescription: 'Επιλογές αποστολής',
    //       options: [{
    //         id: 12,
    //         description: 'Παραλαβή από κατάστημα',
    //         price: 0.25,
    //       },
    //       ],
    //     },
    //   ],
    //   price: 2,
    //   state: 'Ολοκληρωμένη',
    //   user_id: 1,
    // },
  ];


  constructor(private commonService: CommonService) {
  }

  async getPrintingOptions() {
    try {
      const category = await this.commonService.getRequest(`category`);
      return category;
    } catch (e) {
      throw e;
    }
  }

  async getShippingOptions() {
    try {
      const ship = await this.commonService.getRequest(`shipping?is_active=true`);
      return ship;
    } catch (e) {
      throw e;
    }
  }

  async getPrintingOrders() {
    try {
      const orders = await this.commonService.getRequest(`request`);
      return orders;
    } catch (e) {
      throw e;
    }
  }

  async getPaymentMethods() {
    try {
      const paymentOptions = await this.commonService.getRequest(`payment?is_active=true`);
      return paymentOptions;
    } catch (e) {
      throw e;
    }
  }

  async searchPrint(item) {
    try {
      await this.commonService.getRequest(`request/search?model=${item}`);
    } catch (e) {
      throw e;
    }
  }

  /**
   * Description: Returns the number of user's pending prints
   * @returns number
   */
  async getPendingCounter() {
    try {
      let response = await this.commonService.getRequest('request/count?status_id=1')
      return response.count
    } catch (e) {
      throw e;
    }
  }

  /**
   * Description: Returns the completed orders of user
   * @returns number
   */
    async getCompletedOrders() {
    try {
      let response = await this.commonService.getRequest('request?status_id=3&admin=false')
      return response
    } catch (e) {
      throw e;
    }
  }

  /**
   * Description: Returns the pending orders of user
   * @returns number
   */
  async getPendingOrders() {
    try {
      let response = await this.commonService.getRequest('request?status_id=1&admin=false')
      return response
    } catch (e) {
      throw e;
    }
  }

  async getPrintOptions(print: any) {
    try {
      let response = await this.commonService.getRequest(`request/details/${print.id}`)

      if (response) {
        return response
      } else {
        return []
      }

    } catch (e) {
      throw e;
    }
  }
}
