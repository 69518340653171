import { CommonService } from 'src/app/services/common.service';
import {Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {Router} from '@angular/router';
import { ConfirmPaymentDialogComponent } from '../components/confirm-payment-dialog/confirm-payment-dialog.component';
import { ActionCompletionDialogComponent } from '../components/action-completion-dialog/action-completion-dialog.component';

@Injectable()
export class PaymentService {
  private readonly serviceModel: string;

  constructor(private matDialog: MatDialog,
              private router: Router,
              private commonService: CommonService) {
    this.serviceModel = 'payment';
  }

  public async downloadPDF(id: string): Promise<any> {
    // try {
    //   return await this.databaseService.getRequest(`payment_report/${id}`);
    // } catch (error) {
    //   throw error;
    // }
  }

  public handlePaymentResult(activatedRoute): void {
    const id = activatedRoute.snapshot.queryParams.id;
    const mode = activatedRoute.snapshot.queryParams.mode;
    if (mode === 'cancel_payment') {
      this.openActionCompletionDialog('Παρουσιάστηκε κάποιο πρόβλημα με την πληρωμή, η παραγγελία σας δεν καταχωρήθηκε.', 'Η συναλλαγή σας ακυρώθηκε.');
    }
    if (mode === 'CANCELED') {
      this.openActionCompletionDialog('Παρουσιάστηκε κάποιο πρόβλημα με την πληρωμή, η παραγγελία σας δεν καταχωρήθηκε.', 'Η συναλλαγή σας ακυρώθηκε.');
    }
    if (mode === 'ERROR') {
      this.openActionCompletionDialog('Παρουσιάστηκε κάποιο πρόβλημα με την πληρωμή, η παραγγελία σας δεν καταχωρήθηκε.', 'Η συναλλαγή σας ακυρώθηκε.');
    }
    if (mode === 'REFUSED') {
      this.openActionCompletionDialog('Απορριφθείσα συναλλαγή, η παραγγελία σας δεν καταχωρήθηκε.', 'Η συναλλαγή σας απορρίφθηκε.');
    }
    if (mode === 'confirm_payment') {
      this.router.navigate([], {
        queryParamsHandling: ''
      }).then(() => {
        this.matDialog.open(ConfirmPaymentDialogComponent, {
          data: {
            id
          }
        });
      });
    }
  }

  private openActionCompletionDialog(message: string, title: string): void {
    this.router.navigate([], {
      queryParamsHandling: ''
    }).then(() => {
      this.matDialog.open(ActionCompletionDialogComponent, {
        data: {
          message,
          title
        }
      });
    });
  }

  public async produceDigest(payment: any): Promise<any> {
    try {
      return await this.commonService.postRequest(`${this.serviceModel}/digest`, payment);
    } catch (error) {
      throw error;
    }
  }

  public async searchRFID(payment: any): Promise<any> {
    try {
      // return await this.databaseService.postRequest(payment, `${this.serviceModel}/search`);
    } catch (error) {
      throw error;
    }
  }
}
