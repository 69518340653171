import {Component, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {PrintService} from '../../services/print.service';
import {PrintingOption} from '../../interfaces/printingOption';
import {FileUploadService} from '../../services/file-upload.service';
import {PrintOrderDetails} from '../../interfaces/printOrder';
import {DataService} from '../../services/data.service';


@Component({
  selector: 'app-print-dialog',
  templateUrl: './print-dialog.component.html',
  styleUrls: ['./print-dialog.component.scss']
})
export class PrintDialogComponent implements OnInit {
  optionsLoaded: boolean;
  printingOptions: PrintingOption[] = [];
  selectedOption = [];
  noOptionsCategories = [];
  totalPrice = 0;
  isDisabled: boolean;
  color: any;
  binding: any;
  direction: any;
  paper: any;
  page: any;
  copies: any;
  sendCopy: any;
  optionsDescription = [];

  constructor(public dialogRef: MatDialogRef<PrintDialogComponent>,
              private printService: PrintService,
              private formBuilder: FormBuilder,
              private uploadService: FileUploadService,
              public dataService: DataService) {
    this.optionsLoaded = false;
    this.isDisabled = true;
  }

  async ngOnInit() {
    await this.getOptions();
    console.log(this.printingOptions);
  }

  async getOptions() {
    try {
      this.printingOptions = await this.printService.getPrintingOptions();
      this.optionsLoaded = true;
    } catch (e) {
      console.log(e);
    }
  }

  async onFileSelected(event) {
    // await this.uploadService.uploadFile(event);
  }

  closeDialog(model?: any ) {
    this.dialogRef.close(model);
  }

  notDisabled() {
    this.isDisabled = false;
  }

  getTotal() {
    // this.total = 0;
    // let price = 0;
    // for (const item of this.selectedOption) {
    //   this.total += item.price;
    // }
    // this.noOptionsCategories = this.printingOptions.filter(option => option.options.length === 0);
    // for (const option of this.noOptionsCategories) {
    //   price = option.value * option.price;
    // }
    // this.total += price;
    // // console.log(this.total);
    // return this.total;
  }

  async printOrder() {
    let price = 0;
    this.totalPrice = (this.color.price + this.binding.price + this.direction.price + this.paper.price
      + this.page.price + this.sendCopy.price).toFixed(1);
    this.noOptionsCategories = this.printingOptions.filter(option => option.properties.length === 0);
    for (const option of this.noOptionsCategories) {
      price = option.value * option.price;
    }
    this.totalPrice += price;
    const model = {
      color: this.color,
      binding: this.binding,
      direction: this.direction,
      paper: this.paper,
      page: this.page,
      copies: this.copies,
      sendCopy: this.sendCopy,
      price: this.totalPrice
    };
    // const printOrder: PrintOrderDetails = {
    //   id: 1,
    //   printingOptions: [] ,
    //   price: 0,
    //   state: 'Σε αναμονή'
    // };
    if (localStorage.getItem('model')) {
      const stored = JSON.parse(localStorage.getItem('model'));
      stored.push(model);
      this.dataService.orders = stored;
      localStorage.setItem('model', JSON.stringify(stored));
    } else {
      localStorage.setItem('model', JSON.stringify([model]));
      this.dataService.orders = [model];
    }
    this.closeDialog(model);

    // this.selectedOption.push(this.noOptionsCategories[0]);
    // const model = [
    //   {
    //     id: 1,
    //     printOptions: [this.selectedOption],
    //     price: this.total,
    //     state: 'Pending',
    //     // user_id:
    //   }];
    // console.log(model);
    // await this.printService.printOrder(model);
  }
}
