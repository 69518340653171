import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-add-receipt-dialog',
  templateUrl: './add-receipt-dialog.component.html',
  styleUrls: ['./add-receipt-dialog.component.scss']
})
export class AddReceiptDialogComponent implements OnInit {

  first_name = ""
  last_name = ""
  email = ""

  update = false;
  form: FormGroup;
  
  constructor(public dialogRef: MatDialogRef<AddReceiptDialogComponent>,
              public formBuilder: FormBuilder,
      @Inject(MAT_DIALOG_DATA) data) { 

        if (data) {
          if (data.first_name) {
            this.first_name = data.first_name
          }

          if (data.last_name) {
            this.last_name = data.last_name
          }

          if (data.email) {
            this.email = data.email
          }

          this.update = true
        }
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      first_name: [
        '',
        Validators.compose([
          Validators.maxLength(45),
          Validators.required,
        ]),
      ],
      last_name: [
        '',
        Validators.compose([
          Validators.maxLength(45),
          Validators.required,
        ]),
      ],
      email: [
        '',
        Validators.compose([
          Validators.email,
          Validators.required,
        ]),
      ],
    });
  }

  isDisabled() {
    return !this.first_name || !this.last_name || !this.email
  }

  submit() {
    this.dialogRef.close({
      first_name: this.first_name,
      last_name: this.last_name,
      email: this.email,
      is_invoice: 0
    });

  }

  closeDialog() {
    this.dialogRef.close();
  }

}
