import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-invoice-display-dialog',
  templateUrl: './invoice-display-dialog.component.html',
  styleUrls: ['./invoice-display-dialog.component.scss']
})
export class InvoiceDisplayDialogComponent implements OnInit {

  info;

  constructor(public dialogRef: MatDialogRef<InvoiceDisplayDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data) { 
      this.info = data
    }

  ngOnInit(): void {
  }

}
