import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { CommonService } from 'src/app/services/common.service';
import { ToastMessagesService } from 'src/app/services/toast-messages.service';

@Component({
  selector: 'app-change-password-dialog',
  templateUrl: './change-password-dialog.component.html',
  styleUrls: ['./change-password-dialog.component.scss']
})
export class ChangePasswordDialogComponent implements OnInit {

  password = ""
  confirm_password = ""
  hide = true;
  hide_confirm = true;

  form = this.formBuilder.group({
    password: [
      '',
      Validators.compose([
        Validators.minLength(5),
        Validators.required,
      ]),
    ],
    confirm_password: [
      '',
      Validators.compose([
        Validators.minLength(5),
        Validators.required,
      ]),
    ],
  });

  constructor(private toastMessagesService: ToastMessagesService,
    private commonService: CommonService,
    public dialogRef: MatDialogRef<ChangePasswordDialogComponent>,
    public formBuilder: FormBuilder) { }

  ngOnInit(): void {
  }

  closeDialog(data: any = false) {
    this.dialogRef.close(data);
  }

  validPassword(password) {
    var re = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[a-zA-Z0-9]+$")
    return re.test(password)
  }

  validFields(error = false) {
    if (this.form.valid) {
      return (this.form.get('password')?.value && this.form.get('confirm_password')?.value)
        && (this.form.get('password')?.value === this.form.get('confirm_password')?.value);
    }
    if (error) {
      if (this.form.get('password')?.value && this.form.get('confirm_password')?.value) {
        return (this.form.get('password')?.value && this.form.get('confirm_password')?.value)
          && (this.form.get('password')?.value === this.form.get('confirm_password')?.value);
      } else {
        return true;
      }
    }
    return false;
  }

  submit() {
    this.closeDialog(this.form.get('password')?.value)
  }

  onPasswordChange() {
    if (this.form.get('password')?.value === this.form.get('confirm_password')?.value) {
     this.form.get('confirm_password').setErrors(null);
    } else {
      this.form.get('confirm_password').setErrors({ mismatch: true });
    }
  }
}
