import {Component, OnInit} from '@angular/core';
import {UserService} from '../../services/users/user.service';
import {Router} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';
import {FormGroup, FormControl, Validators} from '@angular/forms';
import {ToastMessagesService} from '../../services/toast-messages.service';
import { ProgressSpinnerDialogService } from 'src/app/services/progress-spinner-dialog.service';


@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  username: string;
  password: string;
  hide: boolean;
  stepTwo: boolean;
  error: boolean;

  userInfoForm = new FormGroup({
    firstName: new FormControl('', Validators.required),
    username: new FormControl('', Validators.required),
    password: new FormControl('', Validators.required),
    lastName: new FormControl('', Validators.required),
    email: new FormControl('', Validators.required),
    phoneNumber: new FormControl('', Validators.required),
    address: new FormControl('', Validators.required),
    postalCode: new FormControl('', Validators.required),
  });

  constructor(private userService: UserService,
              private router: Router, 
              public toastMessagesService: ToastMessagesService,
              private progressSpinnerDialogService: ProgressSpinnerDialogService) {
    this.hide = true;
    this.stepTwo = false;
    this.error = false;
    this.username = '';
    this.password = '';
  }

  async ngOnInit(): Promise<void> {
    if (!navigator.onLine) {
      this.toastMessagesService.toastErrorMessage("Δεν υπάρχει σύνδεση στο διαδίκτυο.");
      await this.router.navigateByUrl('/');
    }
  }

  isInputValid(){
    if (this.username === '' || this.password === ''){
      return false;
    }else{
      return true;
    }
  }


  getNextStep() {
      this.userInfoForm.controls.username.setValue(this.username);
      this.userInfoForm.controls.password.setValue(this.password);
      this.stepTwo = true;
    }

  getPreviousStep() {
    this.stepTwo = false;

  }

  async registerUser() {
    try {
      // const userInfo = this.userInfoForm.value;
      const user = {
        // id: null,
        first_name: this.userInfoForm.get('firstName').value,
        last_name: this.userInfoForm.get('lastName').value,
        email: this.userInfoForm.get('email').value,
        username: this.username,
        password: this.password,
        is_admin_user: 0,
        created_by: null,
        address: this.userInfoForm.get('address').value,
        postal_code: this.userInfoForm.get('postalCode').value,
        phone_number: this.userInfoForm.get('phoneNumber').value
      };
      console.log(user);

      this.progressSpinnerDialogService.show();

      await this.userService.registerUser(user);

      this.progressSpinnerDialogService.end();

      this.toastMessagesService.toastSuccessMessage('Ο λογαριασμός σας δημιουργήθηκε με επιτυχία.');
      await this.router.navigate(['/login']);
    } catch (e) {

      this.progressSpinnerDialogService.end();

      console.log(e);
      this.toastMessagesService.toastErrorMessage(e.error.message);
    }
  }

  isFormInvalid() {
    return this.userInfoForm.valid;
  }

}
