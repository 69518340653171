import { PaymentMethods } from './../../interfaces/paymentMethods';
import { CommonService } from './../../services/common.service';
import { TermsComponent } from './../terms/terms.component';
import { ProgressSpinnerDialogService } from './../../services/progress-spinner-dialog.service';
import {Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {FileUploadService} from '../../services/file-upload.service';
import {PrintingOption} from '../../interfaces/printingOption';
import {PrintService} from '../../services/print.service';
import {PrintOrderDetails} from '../../interfaces/printOrder';
import {filter} from 'rxjs/operators';
import {DataService} from '../../services/data.service';
import {ActivatedRoute, Router} from '@angular/router';
import {PrintModel} from '../../interfaces/printModel';
import {ShippingOption} from '../../interfaces/shippingOption';
import {InterceptService} from '../../interceptors/intercept.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {UserService} from '../../services/users/user.service';
import {ToastMessagesService} from '../../services/toast-messages.service';
import { ProgressSpinnerDialogComponent } from '../progress-spinner-dialog/progress-spinner-dialog.component';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { MatExpansionPanel } from '@angular/material/expansion';
import { LoginDialogComponent } from '../login-dialog/login-dialog.component';
import {environment} from '../../../environments/environment';
import { PaymentService } from 'src/app/services/payment.service';
@Component({
  selector: 'app-new-print',
  templateUrl: './new-print.component.html',
  styleUrls: ['./new-print.component.scss']
})
export class NewPrintComponent implements OnInit {

  constructor(private uploadService: FileUploadService,
              private printService: PrintService,
              private router: Router, public toastMessagesService: ToastMessagesService,
              private userService: UserService,
              private dialog: MatDialog,
              private progressSpinnerDialogService: ProgressSpinnerDialogService,
              private commonService: CommonService,
              private loginDialog: MatDialog,
              private paymentService: PaymentService,
              private activatedRoute: ActivatedRoute) {
    this.optionsLoaded = false;
    this.paymentShow = false;
    this.disabled = true;
    // this.notAllSelected = true;

    this.paymentService.handlePaymentResult(activatedRoute);
  }

  @Input() is_guest: Boolean;
  @Output() logged = new EventEmitter();
  @ViewChildren('selectionPanel') selectionPanel: QueryList<MatExpansionPanel>;
  @ViewChildren('costPanel') costPanel: QueryList<MatExpansionPanel>;


  @ViewChild('totalWindow', {read: ElementRef})
  public totalWindow: ElementRef;
  contactData: any;

  printingOptions: PrintingOption[] = [];
  print: any;
  optionsLoaded: boolean;
  paymentMethod: any;
  paymentSelected: any;
  // total = 0;
  totalTemp = 0;
  paymentShow: boolean;
  disabled: boolean;
  filesToUpload = [];
  pageSizes = [];
  shippingOptions: ShippingOption[] = [];
  shippingsFromPayment = [];
  shippingMethod: any;
  requestModel: any;
  files: Array<File> = [];
  notAllSelected: boolean;
  internet_connection = true;
  display_total = false;
  position;
  terms = false;
  payment_types = [];
  showPaymentForm = false;
  paymentSelection;
  // tslint:disable-next-line:variable-name
  shipping_id;

  availableReceipts = [];
  selectedReceipt;

  userInvoices = [];
  selectedInvoice;

  new_invoice = false;
  new_receipt = false;

  notes = '';
  extraShipCost = 0;

  checkresultScroll = false;

  guestUserForm = new FormGroup({
    firstName: new FormControl('', Validators.required),
    lastName: new FormControl('', Validators.required),
    email: new FormControl('', Validators.compose([
      Validators.required,
      Validators.email,
    ])),
    phoneNumber: new FormControl('', Validators.compose([
      Validators.required,
      Validators.pattern('[- +()0-9]+')
    ])),
    address: new FormControl('', Validators.required),
    postalCode: new FormControl('',  Validators.compose([
      Validators.maxLength(45),
      Validators.required,
      Validators.pattern('^[0-9]*$')
    ])),
  });

  invoiceForm = new FormGroup({
    company_name: new FormControl('', Validators.required),
    occupation: new FormControl('', Validators.required),
    address1: new FormControl('', Validators.required),
    address2: new FormControl(''),
    city: new FormControl('', Validators.required),
    area: new FormControl('', Validators.required),
    postal_code: new FormControl('',  Validators.compose([
      Validators.maxLength(45),
      Validators.required,
      Validators.pattern('^[0-9]*$')
    ])),
    country: new FormControl('', Validators.required),
    invoice_email: new FormControl('', Validators.compose([
      Validators.required,
      Validators.email,
    ])),
    vat_number: new FormControl('', Validators.required),
    tax_office: new FormControl('', Validators.required),
  });

  // receiptForm = new FormGroup({
  //   first_name: new FormControl('', Validators.required),
  //   last_name: new FormControl('', Validators.required),
  //   email: new FormControl('', Validators.compose([
  //     Validators.required,
  //     Validators.email,
  //   ])),
  // });

  fileSelected = false;
  onlinePayment = false;
  recipient_details = false;
  scalePrice: any;
  online_payment = false;
  paymentId;
  totalPages: number;

  @HostListener('window:scroll', ['$event'])
    onScroll(event) {
      this.checkScroll(event);
    }

  async ngOnInit() {
    if (!navigator.onLine) {
      this.toastMessagesService.toastErrorMessage('Η εφαρμογή χρειάζεται σύνδεση στο διαδίκτυο.');
      this.internet_connection = false;
    } else {
      this.progressSpinnerDialogService.show();
      this.contactData = await this.commonService.getRequest('communication');
      this.progressSpinnerDialogService.end();
    }
  }

  isValid() {

    let valid = true;

    if (this.filesToUpload.length <= 0) {
      valid = false;
    }

    this.filesToUpload.forEach(el => {
      if (el.orientation === null || typeof(el.page) !== 'number') {
        valid = false;
      }

      let validLength = 0;
      let currentLength = 0;

      el.printingOptions.forEach(element => {
        if (this.isValidCategory(element)) {
          validLength++;
        }
      });

      el.selectedOptions.forEach(element => {
        currentLength++;
      });

      const allValuesTrue = el.selectedOptions.filter(value => value.canShow === false);
      if (allValuesTrue && allValuesTrue.length > 0) {
        valid = false;
      }

      if (currentLength !== validLength) {
        valid = false;
      }

    });

    return valid;
  }

  userExists() {
    if (localStorage.getItem('token') === null) {
      return false;
    } else {
      return true;
    }
  }

  backToLogin(){

  }

  async getPayments() {
    try {
      this.payment_types = await this.commonService.getRequest('payment');
    } catch (error) {
      console.log(error);
    }
  }

  async getOptions() {
    try {
      this.printingOptions = await this.printService.getPrintingOptions();
      for (const option of this.printingOptions) {
        let count = 0;
        for (const property of option.properties) {
          property.canShow = true;
          if (+property.is_active === 0) {
            count++;
            // console.log(count);
          }
        }
        if (count === option.properties.length) {
          const currentOptionIndex = this.printingOptions.findIndex(r => r.id === option.id);
          if (currentOptionIndex !== -1) {
            this.printingOptions.splice(currentOptionIndex, 1);
          }
        }
      }
      this.shippingOptions = await this.printService.getShippingOptions();
      this.paymentMethod = await this.printService.getPaymentMethods();

      if (this.is_guest === undefined || !this.is_guest) {
        const receipts = await this.commonService.getRequest('document?is_invoice=0');
        const invoices = await this.commonService.getRequest('document?is_invoice=1');

        this.userInvoices = [...new Set(invoices)];
        this.availableReceipts = [...new Set(receipts)];

      }


      this.optionsLoaded = true;
      this.paymentShow = false;

    } catch (e) {
      console.log(e);
    }
  }

  async onFileSelected(event) {

    if (this.filesToUpload.length === 0) {
      this.pageSizes = [];
    }
    this.closeOtherPanels();
    this.fileSelected = true;
    const added_files = event.target.files;
    if (added_files.length > 0) {
      for (const file of added_files) {
        this.progressSpinnerDialogService.show();
        await this.getOptions();
        const isLocked = await this.isLocked(file);

        if (isLocked) {
          this.toastMessagesService.toastErrorMessage('Δεν μπορείτε να επιλέξετε κλειδωμένα αρχεία.');
          this.files = [];
          this.filesToUpload = [];
          break;
        }

        if (this.filesToUpload.length === 10) {
          this.toastMessagesService.toastErrorMessage('Μπορείτε να επιλέξετε εως 10 αρχεία.');
          break;
        }

        this.files.push(file);

        const count = await this.getFilePages(file);

        const model = {
          file,
          filePages: count,
          printingOptions: this.printingOptions,
          selectedOptions: [],
          orientation: null,
          page: null,
          copies: 1,
          cost: 0
        };

        this.filesToUpload.push(model);

        if (this.filesToUpload.length) {
          this.display_total = true;
        } else {
          this.display_total = false;
        }

        for (const category of model.printingOptions) {
          for (const property of category.properties) {
            this.propertyPages2(count, property);
          }
        }

        this.progressSpinnerDialogService.end();
      }

    }
  }

  async getFilePages(file) {
    let count;
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsText(file);
      reader.onloadend = async () => {
        try {
          if (typeof reader.result === 'string') {
            count = reader.result.match(/\/Type[\s]*\/Page[^s]/g).length;
            this.pageSizes.push(count);
            resolve(count);
          }
        } catch (error) {
          reject(error);
        }
        reader.onerror = (error) => {
          reject(error);
        };
      };
    });
  }

  removefile(event, item) {
    event.preventDefault();
    event.stopPropagation();

    const index = this.filesToUpload.indexOf(item);

    this.filesToUpload.splice(index, 1);
    this.pageSizes.splice(index, 1);
    this.files.splice(index, 1);

    if (!this.filesToUpload.length) {
      this.display_total = false;
    }

  }

  goBack() {
    this.paymentShow = false;
  }

  async navigateHome() {
    await this.router.navigate(['/home/main']);
  }

  round(num, decimalPlaces = 0) {
    const p = Math.pow(10, decimalPlaces);
    const m = (num * p) * (1 + Number.EPSILON);
    return Math.round(m) / p;
  }

  getCostProperties() {
   // let properties = ["printingOptions", ]

  }

  getTotal() {
    this.totalTemp = 0;
    if (this.filesToUpload.length > 0) {
      for (let y = 0; y < this.filesToUpload.length; y++) {
        let filePrice = 0;
        let totalTemp = 0;
        // this.totalPages + this.filesToUpload[y].filePages * this.filesToUpload[y].copies

        // if(this.shipping_id == 1){
        //   this.totalTemp = this.totalTemp + this.shippingCost(this.filesToUpload[y]);
        // }

        if (this.filesToUpload[y].selectedOptions.length > 0) {
          for (let i = 0; i < this.filesToUpload[y].selectedOptions.length; i++) {
            if (this.filesToUpload[y].selectedOptions[i] !== undefined) {
              if (this.filesToUpload[y].printingOptions[i].is_main === 1) {
                if (this.filesToUpload[y].selectedOptions[i].has_scale && this.filesToUpload[y].selectedOptions[i].canShow === true) {
                  const scales = this.filesToUpload[y].selectedOptions[i].scales;
                  let currentScale;
                  const pagesNumber = this.filesToUpload[y].page && this.filesToUpload[y].page === 1 ? this.findPageForTotalPrice(this.filesToUpload[y].filePages)
                    : this.pageSizes[y] ;
                  scales.forEach(scale => {
                    if (pagesNumber >= scale.from && pagesNumber <= scale.to) {
                      currentScale = scale;
                    }
                  });
                  if (currentScale) {
                    this.scalePrice = +currentScale.price;
                    this.round(filePrice += +currentScale.price, 2);
                  } else {
                    this.toastMessagesService.toastErrorMessage(`Η επιλογή ${this.filesToUpload[y].selectedOptions[i].description} δεν είναι διαθέσιμη.`);
                  }
                } else {
                  this.round(filePrice += +this.filesToUpload[y].selectedOptions[i].price, 2);
                }
              } else {
                if (this.filesToUpload[y].selectedOptions[i].has_scale && this.filesToUpload[y].selectedOptions[i].canShow === true) {
                  const scales = this.filesToUpload[y].selectedOptions[i].scales;
                  let currentScale;
                  const pagesNumber = this.filesToUpload[y].page && this.filesToUpload[y].page === 1 ? this.findPageForTotalPrice(this.filesToUpload[y].filePages)
                    : this.pageSizes[y] ;
                  const checkForScale = scales.find(scale => pagesNumber >= scale.from && pagesNumber <= scale.to);
                  currentScale = checkForScale;
                  if (currentScale) {
                    this.scalePrice = +currentScale.price;
                    this.round(totalTemp += +currentScale.price, 2);
                  } else {
                    this.toastMessagesService.toastErrorMessage(`Η επιλογή ${this.filesToUpload[y].selectedOptions[i].description} δεν είναι διαθέσιμη.`);
                  }

                } else {
                  this.round(totalTemp += +this.filesToUpload[y].selectedOptions[i].price, 2);
                }
              }
            }
          }
          filePrice = this.round(filePrice * +this.pageSizes[y], 2);
          filePrice += this.round(totalTemp, 2);

          this.round(this.filesToUpload[y].cost = filePrice * this.filesToUpload[y].copies, 2);
          this.round(this.totalTemp += +this.filesToUpload[y].cost, 2);
        }
      }
      let paymentPrice;
      if (this.paymentSelected) {
        paymentPrice = this.paymentMethod.find(r => r.id === this.paymentSelected);
        if (paymentPrice.price !== null) {
          this.totalTemp = this.round(this.totalTemp + +paymentPrice.price, 2);
        }
      }
      if (this.shipping_id) {
        const shippingPrice = this.shippingOptions.find(r => r.id === this.shipping_id);
        // shipping/payments approach
        if (paymentPrice.has_shipping === 0) {
          this.shipping_id = 2;
        }
        this.totalTemp = this.totalTemp + shippingPrice.extraShipCost;

        if (shippingPrice.price !== null) {
          this.totalTemp = this.round(this.totalTemp + +shippingPrice.price * paymentPrice.has_shipping, 2);
        }
      }
    }
    return this.round(this.totalTemp, 2).toFixed(2);
  }

  savePrint() {
    let totalPages = 0;
    if (this.filesToUpload.length > 0) {
      let model = [];
      let idTable = [];
      const costTable = [];
      const detailsTable = [];
      for (let i = 0; i < this.filesToUpload.length; i++) {
        idTable = [];
        model = [];

        totalPages = totalPages + this.filesToUpload[i].filePages * this.filesToUpload[i].copies;

        this.shippingOptions.forEach(ship => {
          let extraShipCost = 0;
          if (ship.has_scale === 1) {
            for (const scale of ship.scales) {
              if (totalPages >= scale.from && totalPages <= scale.to){
                extraShipCost = +scale.price;
                this.extraShipCost = +scale.price;
                this.totalTemp = this.totalTemp + +scale.price;
              }
            }
          }
          ship.extraShipCost = extraShipCost;
        });

        model.push(this.filesToUpload[i].selectedOptions);
        for (const item of model) {
          for (const opt of item) {
            idTable.push(opt.id);
          }
        }
        const details = {
          page_orientation: this.filesToUpload[i].orientation,
          page_side: this.filesToUpload[i].page,
          file_pages: this.filesToUpload[i].filePages,
          copies: this.filesToUpload[i].copies,
          properties: idTable
        };
        this.filesToUpload[i].cost = this.round(this.filesToUpload[i].cost, 2);
        costTable.push(this.filesToUpload[i].cost);
        detailsTable.push(details);
        this.paymentShow = true;
        window.scroll(0, 0);
      }
      let total = 0;
      for (const item of costTable) {
        total += item;
      }
      this.requestModel = {
        // id: null,
        date_created: null,
        date_completed: null,
        user_id: null,
        shipping_method: null,
        total_price: total,
        details: detailsTable,
        notes: this.notes
      };
    } else {
      this.toastMessagesService.toastErrorMessage('Δεν έχετε επιλέξει αρχείο.');
    }
  }

  async completeOrder() {
    this.progressSpinnerDialogService.show();
    const document = [];
    let is_paid = 0;

    if (this.paymentSelection === 1) {

      if (!this.is_guest && this.selectedInvoice) {
        document.push({
          id: this.selectedInvoice,
          is_invoice: 1
        });
      } else {
        document.push({
          company_name: this.invoiceForm.get('company_name')?.value,
          occupation: this.invoiceForm.get('occupation')?.value,
          address1:  this.invoiceForm.get('address1')?.value,
          address2:  this.invoiceForm.get('address2')?.value,
          city:  this.invoiceForm.get('city')?.value,
          area:  this.invoiceForm.get('area')?.value,
          postal_code:  this.invoiceForm.get('postal_code')?.value,
          country:  this.invoiceForm.get('country')?.value,
          invoice_email:  this.invoiceForm.get('invoice_email')?.value,
          vat_number:  this.invoiceForm.get('vat_number')?.value,
          tax_office:  this.invoiceForm.get('tax_office')?.value,
          is_invoice: 1
        });
      }

    } else if (this.paymentSelection === 0) {
      document.push({is_invoice: 0});
    }

    // if paid with card, set print as paid
    if (this.paymentSelected === 2) {
      is_paid = 1;
    }
    const model = {
      date_created: this.requestModel.date_created,
      date_completed: this.requestModel.date_completed,
      user_id: this.requestModel.user_id,
      shipping_id: this.shipping_id,
      is_invoice: this.paymentSelection,
      total_price: this.requestModel.total_price,
      payment_id: this.paymentSelected,
      details: this.requestModel.details,
      document,
      is_paid,
      notes: this.notes
    };

    this.print = model;

    try {
      let checkPaymentShipping;

      if (this.hasShippingMethod(this.print.payment_id)) {
        checkPaymentShipping = this.print.shipping_id !== undefined && this.print.payment_id !== undefined;
      } else {
        checkPaymentShipping = this.print.payment_id !== undefined;
      }

      if (checkPaymentShipping) {
        if (this.is_guest === undefined || !this.is_guest) {

          model.user_id = JSON.parse(localStorage.getItem('user')).id;

          this.print.is_guest_user = 0;

          if (this.recipient_details) {
            this.print.recipient_details = {
              first_name: this.guestUserForm.get('firstName').value,
              last_name: this.guestUserForm.get('lastName').value,
              email: this.guestUserForm.get('email').value,
              address: this.guestUserForm.get('address').value,
              postal_code: this.guestUserForm.get('postalCode').value,
              phone_number: this.guestUserForm.get('phoneNumber').value,
            };
          }

          const response = await this.uploadService.uploadFile(this.print, this.files);

          if (this.online_payment) {
            await this.onlinePay(response.orderId, this.print.total_price);
          } else {
            this.progressSpinnerDialogService.end();
            await this.router.navigate(['/home/pending-order']);
            this.toastMessagesService.toastSuccessMessage('Ευχαριστούμε! Έχουμε λάβει την παραγγελία σου.');
          }

        } else if (this.guestUserForm.status !== 'INVALID') {
          const user = {
            first_name: this.guestUserForm.get('firstName').value,
            last_name: this.guestUserForm.get('lastName').value,
            email: this.guestUserForm.get('email').value,
            address: this.guestUserForm.get('address').value,
            postal_code: this.guestUserForm.get('postalCode').value,
            phone_number: this.guestUserForm.get('phoneNumber').value,
          };
          const newUser = await this.userService.registerUser(user);
          model.user_id = newUser;
          this.print.is_guest_user = 1;

          const response = await this.uploadService.uploadFile(this.print, this.files);
          if (this.online_payment) {

            await this.onlinePay(response.orderId, this.print.total_price);
          } else {
            this.progressSpinnerDialogService.end();
            this.toastMessagesService.toastSuccessMessage('Ευχαριστούμε! Έχουμε λάβει την παραγγελία σου.');
          }
          this.filesToUpload = [];
          this.paymentShow = false;
          this.guestUserForm.reset();
          this.invoiceForm.reset();
          this.shipping_id = undefined;
          this.totalTemp = 0;
          this.paymentSelected = undefined;
          this.display_total = false;
          this.fileSelected = false;

        } else {
          this.toastMessagesService.toastErrorMessage('Όλα τα πεδία είναι υποχρεωτικά.');
        }
      } else {
        this.toastMessagesService.toastErrorMessage('Όλα τα πεδία είναι υποχρεωτικά.');
      }
      this.progressSpinnerDialogService.end();
    } catch (error) {
      console.log(error);
      this.progressSpinnerDialogService.end();
    }

  }


  isValidCategory(category) {

    let properties_count = category.properties.length;

    if (properties_count) {
      category.properties.forEach(element => {
        if (!element.is_active) {
          properties_count--;
        }
      });

      if (properties_count) {
        return true;
      }
    }
    return false;
  }

  validGuestForm() {


    if (this.paymentSelection === 1) {
      if (this.is_guest === undefined || !this.is_guest) {
        if (this.selectedInvoice === 0) {
          if (!this.invoiceForm.valid) {
            return false;
          }
        } else if (this.selectedInvoice === undefined) {
          return false;
        }
      } else {
        if (!this.invoiceForm.valid) {
          return false;
        }
      }


    } else if (this.paymentSelection === 0) {
      // return true
      // if (this.is_guest === undefined || !this.is_guest) {

      // if (this.selectedReceipt === 0) {
      //   if (!this.receiptForm.valid) {
      //     return false;
      //   }
      // } else if (this.selectedReceipt === undefined) {
      //   return false;
      // }
      // } else {
      //   if (!this.receiptForm.valid) {
      //     return false;
      //   }
      // }

    } else {
      return false;
    }

    if (this.is_guest !== undefined && this.is_guest) {
      if (!this.terms) {
        return false;
      }
    }

    let checkShippingPayment = true;
    if (this.hasShippingMethod(this.paymentSelected)) {
      checkShippingPayment = this.shipping_id !== undefined && this.paymentSelected !== undefined;
    } else {
      checkShippingPayment = this.paymentSelected !== undefined;
    }

    if (localStorage.getItem('user') !== null) {
      return checkShippingPayment;
    }

      // let checkShippingPayment = true;
      // if (this.hasShippingMethod(this.paymentSelected)) {
      //   checkShippingPayment = this.shipping_id !== undefined && this.paymentSelected !== undefined;
      // } else {
      //   checkShippingPayment = this.paymentSelected !== undefined;
      // }

    if (localStorage.getItem('user') !== null && this.shipping_id) {
        return true;
      }


    return this.guestUserForm.valid && checkShippingPayment;
  }

  closeOtherPanels(openPanel: MatExpansionPanel = null, index = null) {
    this.selectionPanel.forEach(panel => {
      panel.close();
    });

    this.costPanel.forEach(panel => {
      panel.close();
    });
  }

  selectionSelected(openPanel: MatExpansionPanel = null, selectedIndex) {
    this.selectionPanel.forEach(panel => {
      if (panel !== openPanel && panel !== null) {
          panel.close();
      }
    });

    this.costPanel.forEach((panel, index) => {

      if (index === selectedIndex) {
          panel.open();
      } else {
        panel.close();
      }
    });
  }

  costSelected(openPanel: MatExpansionPanel = null, selectedIndex) {
    this.costPanel.forEach(panel => {
      if (panel !== openPanel && panel !== null) {
          panel.close();
      }
    });

    this.selectionPanel.forEach((panel, index) => {

      if (index === selectedIndex) {
          panel.open();
      } else {
        panel.close();
      }
    });
  }

  checkScroll(e) {
    if (this.totalWindow === undefined) {
      return false;
    }

    if (this.position < window.pageYOffset) {
      if (window.pageYOffset > 150) {
        this.checkresultScroll = true;
        this.totalWindow.nativeElement.setAttribute('style', 'top: 7%;  ');
      }
    } else {
      if (window.pageYOffset < 150) {
        this.checkresultScroll = false;

        this.totalWindow.nativeElement.setAttribute('style', 'top: 15%;  ');
      }
    }
    this.position =  window.pageYOffset;
  }

  getPageName(value) {
    if (value === 0) {
      return 'Μονή Σελίδα';
    } else if (value === 1) {
      return 'Διπλή Σελίδα';
    }
  }

  getOrientationName(value) {

    if (value === 0) {
      return 'Οριζόντια';
    } else if (value === 1) {
      return 'Κάθετα';
    }
  }

  async openTerms() {
    const dialog = this.dialog.open(TermsComponent, { data: {logout: false}});

    dialog.afterClosed().subscribe(result => {
      if (result) {
        this.terms = true;
      }
    });
  }

  getCurrentShippingName() {
    if (this.shipping_id) {
      let name = '';

      this.shippingOptions.forEach(element => {

        if (this.shipping_id === element.id) {
          name = element.description;

          if (element.price && parseFloat(element.price) || element.extraShipCost) {
            name += ' ' + (parseFloat(element.price) + element.extraShipCost) + ' €';
          }
        }
      });

      return name;
    }
    return '';
  }

  getCurrentPaymentName() {
    if (this.paymentSelected) {
      let name = '';

      this.paymentMethod.forEach(element => {
        if (this.paymentSelected === element.id) {
          name = element.description;
          if (element.price && parseFloat(element.price) > 0) {
            name += ' ' + element.price + ' €';
          }
        }
      });

      return name;
    }
    return '';
  }

  newReceipt() {
    this.new_receipt = true;
    this.selectedReceipt = 0;
  }

  closeNewReceipt() {
    this.new_receipt = false;
    // this.receiptForm.reset();
  }

  newInvoice() {
    this.new_invoice = true;
    this.selectedInvoice = 0;
  }

  closeNewInvoice() {
    this.new_invoice = false;
    this.invoiceForm.reset();
  }

  quickLogin() {

    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {quick: true};
    dialogConfig.width = '450px' ;

    const loginDialog = this.loginDialog.open(LoginDialogComponent, dialogConfig);

    loginDialog.afterClosed().subscribe(result => {
      if (result !== undefined && result.quick_login) {
        this.loginUser();
      }
    });
  }

  async loginUser() {
    this.is_guest = false;
    this.availableReceipts = await this.commonService.getRequest('document?is_invoice=0');
    this.userInvoices = await this.commonService.getRequest('document?is_invoice=1');
    this.logged.emit(true);
  }

  availableShippings(option) {
    const payment = this.paymentMethod.find((p) => p.id === this.paymentSelected);
    const shippings = payment ? payment.shippings : [];
    return (payment.shippings.findIndex(shipping => shipping === option) === -1);
  }

  hasShippingMethod(paymentID) {
    const category = this.paymentMethod.find(payment => payment.id === paymentID);
    if (category) {
      this.shippingsFromPayment = category.shippings;
      return category.has_shipping;
    }

    return true;
  }

  checkPayment(e) {
    const current_payment = this.paymentMethod.find((el) => el.id === e.value);
    this.online_payment = current_payment.is_online;
  }

  async onlinePay(orderID, totalCost) {
    try {
      const form: any = document.getElementById('paymentForm');

      const confirmUrl: any = document.getElementsByName('confirmUrl');
      confirmUrl[0].value = `${environment.paymentServer}/confirm`;

      const cancelUrl: any = document.getElementsByName('cancelUrl');
      cancelUrl[0].value = `${environment.paymentServer}/cancel`;

      const formValue = {};
      for (const element of form.elements) {
        if (element.name === 'orderid') {
          formValue[element.name] = orderID;
        } else if (element.name === 'orderAmount') {
          formValue[element.name] = totalCost;
        }
        else {
          formValue[element.name] = element.value;
        }
      }

      const response = await this.paymentService.produceDigest(formValue);
      const digestInput: any = document.getElementsByName('digest');

      digestInput[0].value = response.digest;
      const orderInput: any = document.getElementsByName('orderid');
      orderInput[0].value = orderID;
      const orderAmountInput: any = document.getElementsByName('orderAmount');
      orderAmountInput[0].value = totalCost;
      form.submit();
    } catch (error) {
      console.log(error);
    }
  }

  async isLocked(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onload = async () => {
        const file = new Blob([reader.result], {type: 'application/pdf'});
        file.text().then(x => {
          resolve(x.includes('Encrypt'));
        });
      };
    });
  }

  toggleRecipientDetails() {
    this.recipient_details = !this.recipient_details;
  }

  getRecipientDetailsBtnText() {
    return !this.recipient_details ? 'Προσθέστε διαφορετικά στοιχεία παραλήπτη' : 'Ακύρωση';

  }

  getRecipientDetailsText() {
    return this.recipient_details ? 'Συμπληρώστε νέα στοιχεία παραλήπτη' : 'Συμπληρώστε τα στοιχεία σας';
  }

  propertyPages(pages, property) {
    let endScale = 0;
    let startScale = 0;
    if (property.scales.length) {
      endScale = property.scales[property.scales.length - 1].to;
      startScale = property.scales[0].from;
    } else {
      property.canShow = true;
      return true;
    }
    if ((pages > endScale) || (pages < startScale)) {
      property.canShow = false;
      return false;
    }
    property.canShow = true;
    return true;
  }

  findPageForTotalPrice(filePages) {
    let total = filePages / 2;
    total = this.round(total, 2);
    return total;
  }

  checkIfPropertyIsEnabled(filePages, fileFormat, file) {
    const finalPages = fileFormat === 0 ? filePages : this.findPageForTotalPrice(filePages);
    for (const category of file.printingOptions) {
      for (const property of category.properties) {
        this.propertyPages2(finalPages, property);
        console.log(file)
      }
    }
  }

  propertyPages2(pages, property) {
    let endScale = 0;
    let startScale = 0;
    if (property.scales.length) {
      endScale = property.scales[property.scales.length - 1].to;
      startScale = property.scales[0].from;
    } else {
      property.canShow = true;
      return true;
    }
    if ((pages > endScale) || (pages < startScale)) {
      property.canShow = false;
      return false;
    }
    property.canShow = true;
    return true;
  }

  paymentChanged(event) {
    this.getTotal();
    const payment = this.paymentMethod.find((payment) => payment.id === event.value);
    this.shipping_id = null;
    if (payment) {
      this.online_payment = payment.is_online;
    }
  }
  shippingChanged(event) {
    if (event.value === 1){
      this.savePrint();
      this.getTotal();
      // get ids of property types from step 1 and sum of pages
    }
  }
}

