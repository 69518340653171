<div fxLayout="column" *ngIf="dataLoaded" style="width:100%; height: 100%">
    <div fxLayout="row" fxLayoutAlign="center center center">
      <div class="mat-title" style="margin-top: 100px">Ανακοινώσεις</div>
    </div>
    <mat-divider style="margin: 0.5%"></mat-divider>
  
    <div *ngIf="profile" class="center" style="margin-top: 1%;">
        <div fxLayoutAlign="start">
          <mat-icon class="icon-config badge-color" style="margin-right: 5px;">info</mat-icon>
          <span style="margin-top: 2px;">
            Εδώ μπορείτε να δείτε τις ανακοινώσεις. 
          </span>
        </div>
    </div>

    <div *ngIf="announcementsTable.length > 0" fxLayoutAlign="end center center" [ngStyle.gt-sm]="{'margin-right': '40px'}" fxLayoutAlign.lt-md="center center center">
        <mat-form-field>
          <input matInput type="text" placeholder="Αναζήτηση" [(ngModel)]="search"/>
        </mat-form-field>
    </div>

    <div fxLayout="column" fxLayoutGap="5px" *ngIf="!(announcementsTable | filter: search).length">
        <div class="empty">
          Δεν βρέθηκαν ανακοινώσεις
        </div>
    </div>

    <div *ngIf="announcementsTable.length > 0; else elseBlock"></div>

    <div fxLayout="row wrap" style="padding: 0px 20px 20px 20px">
        <mat-card *ngFor="let announcement of announcementsTable.slice().reverse() | filter: search;" class="mat-elevation-z5 announcement-card" (click)="openContext(announcement)">
            <div fxLayout="row" fxLayoutAlign="start">
                <div fxLayout="column" fxLayoutGap="5px" style="width: 100%;">
                    <div class="details">
                        <div class="detail">
                            <div class="title">
                                <strong>Αρ. ανακοίνωσης</strong>
                            </div>
                            <div>
                                {{announcement.id}}
                            </div>
                        </div>
                        <div class="detail">
                            <div class="title">
                                <strong>Τίτλος</strong>
                            </div>
                            <div>
                                {{announcement.title}}
                            </div>
                        </div>
                        <div class="detail">
                            <div class="title">
                                <strong>Ημερομηνία Δημιουργίας</strong>
                            </div>
                            <div>
                                {{announcement.created_at | date: 'dd-MM-yyyy'}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <mat-card-content *ngIf="announcement.collapsed">
                <div fxLayoutAlign="center" style="margin:10px"><h2>Περιεχόμενο Ανακοίνωσης</h2></div>
                <div style="padding: 10px">
                  <mat-divider></mat-divider>
                </div>
                <div [innerHtml]="announcement.context "></div>
              </mat-card-content>
        </mat-card>
    </div>
</div>
            