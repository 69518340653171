import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-invoice-dialog',
  templateUrl: './invoice-dialog.component.html',
  styleUrls: ['./invoice-dialog.component.scss']
})
export class InvoiceDialogComponent implements OnInit {

  company_name = ""
  occupation = ""
  address1 = ""
  address2 = ""
  city = ""
  area = ""
  postal_code = ""
  country = ""
  invoice_email = ""
  vat_number = ""
  tax_office = ""

  form: FormGroup;

  constructor(public dialogRef: MatDialogRef<InvoiceDialogComponent>, public formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      company_name: [
        '',
        Validators.compose([
          Validators.required,
        ]),
      ],
      occupation: [
        '',
        Validators.compose([
          Validators.required,
        ]),
      ],
      address1: [
        '',
        Validators.compose([
          Validators.required,
        ]),
      ],
      address2: [
        '',
        Validators.compose([
          Validators.required,
        ]),
      ],
      city: [
        '',
        Validators.compose([
          Validators.required,
        ]),
      ],
      area: [
        '',
        Validators.compose([
          Validators.required,
        ]),
      ],
      postal_code: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern("^[0-9]*$")
        ]),
      ],
      country: [
        '',
        Validators.compose([
          Validators.required,
        ]),
      ],
      invoice_email: [
        '',
        Validators.compose([
          Validators.email,
          Validators.required,
        ]),
      ],
      vat_number: [
        '',
        Validators.compose([
          Validators.required,
        ]),
      ],
      tax_office: [
        '',
        Validators.compose([
          Validators.required,
        ]),
      ],
    });
  }

  isDisabled() {
    return !this.company_name ||
      !this.occupation ||
      !this.address1 ||
      !this.address2 ||
      !this.city ||
      !this.area ||
      !this.postal_code ||
      !this.country ||
      !this.invoice_email ||
      !this.vat_number ||
      !this.tax_office
  }

  submit() {
    this.dialogRef.close({
      company_name: this.company_name,
      occupation: this.occupation,
      address1: this.address1,
      address2: this.address2,
      city: this.city,
      area: this.area,
      postal_code: this.postal_code,
      country: this.country,
      invoice_email: this.invoice_email,
      vat_number: this.vat_number,
      tax_office: this.tax_office,
      is_invoice: 1
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }

}
