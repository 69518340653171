<h1 mat-dialog-title>
    <div class="header">
      <span style="font-size: larger; padding-right: 20px;">Αποσύνδεση Χρήστη</span>
      <mat-icon  [mat-dialog-close]="false" style="cursor: pointer;">close</mat-icon>
    </div>
  </h1>
   

<div class="container" mat-dialog-content>
    <p>
        Είστε σίγουρος οτι θέλετε να αποσυνδεθείτε;
    </p>
</div>

<div mat-dialog-actions align="end">
    <button mat-raised-button [mat-dialog-close]="true" class="custom-button" cdkFocusInitial>Αποσύνδεση</button>
</div>
