<div style="padding: 10px;" fxLayout="row" fxLayoutAlign="start center">
    <div fxLayout="column" fxFlex="35">
        <strong>Όνομα Εταιρείας:</strong>
    </div>
    <div fxLayout="column" fxFlex="65">
        {{info.company_name}}
    </div>
</div>
<div style="padding: 10px;" fxLayout="row" fxLayoutAlign="start center">
    <div fxLayout="column" fxFlex="35">
        <strong>Επάγγελμα:</strong>
    </div>
    <div fxLayout="column" fxFlex="65">
        {{info.occupation}}
    </div>
</div>
<div style="padding: 10px;" fxLayout="row" fxLayoutAlign="start center">
    <div fxLayout="column" fxFlex="35">
        <strong>Διεύθυνση 1:</strong>
    </div>
    <div fxLayout="column" fxFlex="65">
        {{info.address1}}
    </div>
</div>
<div style="padding: 10px;" fxLayout="row" fxLayoutAlign="start center">
    <div fxLayout="column" fxFlex="35">
        <strong>Διεύθυνση 2:</strong>
    </div>
    <div fxLayout="column" fxFlex="65">
        {{info.address2}}
    </div>
</div>
<div style="padding: 10px;" fxLayout="row" fxLayoutAlign="start center">
    <div fxLayout="column" fxFlex="35">
        <strong>Πόλη:</strong>
    </div>
    <div fxLayout="column" fxFlex="65">
        {{info.city}}
    </div>
</div>
<div style="padding: 10px;" fxLayout="row" fxLayoutAlign="start center">
    <div fxLayout="column" fxFlex="35">
        <strong>Περιοχή:</strong>
    </div>
    <div fxLayout="column" fxFlex="65">
        {{info.area}}
    </div>
</div>
<div style="padding: 10px;" fxLayout="row" fxLayoutAlign="start center">
    <div fxLayout="column" fxFlex="35">
        <strong>Τ.Κ:</strong>
    </div>
    <div fxLayout="column" fxFlex="65">
        {{info.postal_code}}
    </div>
</div>
<div style="padding: 10px;" fxLayout="row" fxLayoutAlign="start center">
    <div fxLayout="column" fxFlex="35">
        <strong>Χώρα:</strong>
    </div>
    <div fxLayout="column" fxFlex="65">
        {{info.country}}
    </div>
</div>
<div style="padding: 10px;"  fxLayout="row" fxLayoutAlign="start center">
    <div fxLayout="column" fxFlex="35">
        <strong>Email:</strong>
    </div>
    <div fxLayout="column" fxFlex="65">
        {{info.invoice_email}}
    </div>
</div>
<div style="padding: 10px;" fxLayout="row" fxLayoutAlign="start center">
    <div fxLayout="column" fxFlex="35">
        <strong>Α.Φ.Μ:</strong>
    </div>
    <div fxLayout="column" fxFlex="65">
        {{info.vat_number}}
    </div>
</div>
<div style="padding: 10px;" fxLayout="row" fxLayoutAlign="start center">
    <div fxLayout="column" fxFlex="35">
        <strong>Δ.Ο.Υ:</strong>
    </div>
    <div fxLayout="column" fxFlex="65">
        {{info.tax_office}}
    </div>
</div>