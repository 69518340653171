<div fxLayout="column" style="height: 100%;width: 100%">
<div fxLayout="column" fxLayoutAlign="center center center" style="width: 100%; height: 100%">
  <mat-card class="card-config mat-elevation-z6" [ngStyle.lt-sm]="{'width':'80%'}">
  <div fxLayout="row" fxLayoutAlign="center">
    <mat-icon>account_circle</mat-icon>
  </div>
  <div fxLayout="row" fxLayoutAlign="center">
    Σύνδεση χρήστη
  </div>
  <div fxLayout="row">
    <div fxLayout="column" fxFlex="40">
      <div style="margin-top: 20px">Όνομα χρήστη ή Email:</div>
      <div style="margin-top: 40px">Συνθηματικό:</div>
    </div>
    <div fxLayout="column" fxFlex="60">
      <mat-form-field>
        <input matInput [(ngModel)]="username" required/>
      </mat-form-field>
      <mat-form-field>
        <input matInput [type]="hide ? 'password' : 'text'" [(ngModel)]="password" required/>
        <mat-icon style="cursor: pointer;" (click)="hide = !hide"
                  matSuffix>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
      </mat-form-field>
    </div>
  </div>
  <div fxLayout="row" fxLayoutAlign="center">
    <button mat-raised-button [disabled]="!inputsValid()" (click)="userLogin()">Σύνδεση</button>
  </div>
    </mat-card>
  <div fxLayout="row" fxLayoutAlign="end center center">
    <a routerLinkActive="active" routerLink="/register">Εγγραφή νέου χρήστη</a>
  </div>
  <div style="margin-top: 5px;">
    <a routerLinkActive="active" routerLink="/forgot-password">Ανάκτηση κωδικού πρόσβασης</a>
  </div>
  <div style="margin-top: 10px;" class="mat-subheading-2">
    <a routerLinkActive="active" routerLink="/anonymous-print">Πραγματοποιήστε νέα εκτύπωση χωρίς εγγραφή</a>
  </div>
</div>
</div>
