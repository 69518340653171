import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { CommonService } from 'src/app/services/common.service';
import { ToastMessagesService } from 'src/app/services/toast-messages.service';

@Component({
  selector: 'app-forgot-password-dialog',
  templateUrl: './forgot-password-dialog.component.html',
  styleUrls: ['./forgot-password-dialog.component.scss']
})
export class ForgotPasswordDialogComponent implements OnInit {


  constructor(private toastMessagesService: ToastMessagesService,
              private commonService: CommonService,
              public dialogRef: MatDialogRef<ForgotPasswordDialogComponent>,
              public formBuilder: FormBuilder) { }

  form: FormGroup;

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      email: [
        '',
        Validators.compose([
          Validators.email,
          Validators.required
        ]),
      ],
    });
  }

  /**
   * Description: sends put request with the submitted email value in order to
   * retrieve the login data
   */
  async putEmail() {
    let email = this.form.get('email')?.value
    if (!email) {
      this.toastMessagesService.toastErrorMessage("Παρακαλώ προσθέστε ένα έγκυρο email.")
    } else {

      let body = {
        email: email,
        admin_request: 0
      }

      try {
        await this.commonService.putRequest(`user/forgot`, body);
        this.toastMessagesService.toastSuccessMessage("Σας στάλθηκε email με οδηγίες για την ανάκτηση του κωδικού πρόσβασης.")
        this.closeDialog(true);

      } catch (error) {
        this.toastMessagesService.toastErrorMessage(error.error.message)
      }
    }
  }

  closeDialog(data = false) {
    this.dialogRef.close(data);
  }

}
