import { ChangePasswordDialogComponent } from './../change-password-dialog/change-password-dialog.component';
import { InvoiceDisplayDialogComponent } from './../invoice-display-dialog/invoice-display-dialog.component';
import { InvoiceDialogComponent } from './../invoice-dialog/invoice-dialog.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AddReceiptDialogComponent } from './../add-receipt-dialog/add-receipt-dialog.component';
import { ProgressSpinnerDialogService } from 'src/app/services/progress-spinner-dialog.service';
import {Component, Input, OnInit} from '@angular/core';
import {UserService} from '../../services/users/user.service';
import {PrintService} from '../../services/print.service';
import {UserInfo} from '../../interfaces/users';
import {ToastMessagesService} from '../../services/toast-messages.service';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-profile-details-card',
  templateUrl: './profile-details-card.component.html',
  styleUrls: ['./profile-details-card.component.scss']
})
export class ProfileDetailsCardComponent implements OnInit {
  @Input() mode;
  user: any = {};
  printsTable = [];
  newPassword1: string;
  newPassword2: string;
  edit: boolean;
  dataLoaded: boolean;
  prints_loaded: boolean = false;
  loading_prints: boolean = false;

  receipts = []

  invoices = []


  constructor(private userService: UserService,
    private printService: PrintService,
    public toastMessagesService: ToastMessagesService,
    private progressSpinnerDialogService: ProgressSpinnerDialogService,
    private commonService: CommonService,
    private receiptDialog: MatDialog,
    private invoiceDialog: MatDialog,
    private passwordDialog: MatDialog) {
    this.newPassword1 = '';
    this.newPassword2 = '';
    this.edit = false;
    this.dataLoaded = false;
  }

  async ngOnInit() {
    this.user = this.userService.checkLoggedUser();
    this.dataLoaded = true;
  //  this.printsTable = await this.printService.getPrintingOrders();
    // for (const pr of printCard) {
    //   const model = {
    //     prints: pr,
    //     collapsed: false
    //   };
    // }
  }

  isPasswordValid() {
    if (this.newPassword1 === '' || this.newPassword2 === '') {
      return false;
    } else {
      return true;
    }
  }

  isUserValid() {
    if (this.user.first_name === '' || this.user.last_name === '' || this.user.phone_number === '' || this.user.postal_code === ''
      || this.user.email === '' || this.user.address === '' || this.user.phone_number.length !== 10 || this.user.postal_code.length !== 5) {
      return false;
    } else {
      return true;
    }
  }

  async changePassword(dialog_result = null) {
    try {

      if (dialog_result !== null) {
        const user = this.user;
        this.user.password = dialog_result;
        this.progressSpinnerDialogService.show();
        await this.userService.updateUserPassword(user);
        this.progressSpinnerDialogService.end();
        this.toastMessagesService.toastSuccessMessage('Ο κωδικός χρήστη άλλαξε. Παρακαλούμε εισέλθετε με τα νέα σας στοιχεία.');
        this.userService.userLogout();
      } else {
        if (this.newPassword1 === this.newPassword2) {
          const user = this.user;
          this.user.password = this.newPassword1;
          this.progressSpinnerDialogService.show();
          await this.userService.updateUserPassword(user);
          this.newPassword2 = '';
          this.newPassword1 = '';
          this.progressSpinnerDialogService.end();
          this.toastMessagesService.toastSuccessMessage('Ο κωδικός χρήστη άλλαξε. Παρακαλούμε εισέλθετε με τα νέα σας στοιχεία.');
          this.userService.userLogout();
        } else {
          this.toastMessagesService.toastErrorMessage('Οι κωδικοί πρόσβασης δεν ταιριάζουν');
        }
      }

    } catch (e) {
      this.progressSpinnerDialogService.end();

      console.log(e);
    }
  }

  editing() {
    this.edit = !this.edit;
  }

  async editInfo() {
    try {
      this.edit = !this.edit;
      this.progressSpinnerDialogService.show();
      await this.userService.updateUserInfo(this.user);
      this.user = await this.userService.validateUser();
      this.progressSpinnerDialogService.end();
      this.toastMessagesService.toastSuccessMessage("Ο χρήστης αποθηκεύτηκε επιτυχώς")
    } catch (e) {
      this.progressSpinnerDialogService.end();

      console.log(e);
    }
  }

  cancelEdit() {
    this.edit = !this.edit;
  }

  async loadPrints() {
    if (!this.prints_loaded) {
      this.loading_prints = true;
      this.printsTable = await this.printService.getPrintingOrders();
      this.loading_prints = false;
    }
    this.prints_loaded = true
  }

  async loadReceiptData() {
    try {
      let respone = await this.commonService.getRequest('document?is_invoice=0')
      this.receipts = [...new Set(respone)];
    } catch (error) {

    }
  }

  async loadInvoiceData() {
    try {
      let respone = await this.commonService.getRequest('document?is_invoice=1')
      this.invoices = respone
      this.invoices = [...new Set(respone)];
    } catch (error) {

    }
  }

  async newReceipt() {
    const receiptDialog = this.receiptDialog.open(AddReceiptDialogComponent, );

    receiptDialog.afterClosed().subscribe(result => {
      if (result) {
        this.addReceipt(result)
      }
    });
  }

  async addReceipt(data) {
    try {
      let body = data
      this.progressSpinnerDialogService.show();
      await this.commonService.postRequest('document', body)
      this.progressSpinnerDialogService.end();
      await this.loadReceiptData()
      this.toastMessagesService.toastSuccessMessage("Η απόδειξη αποθηκεύτηκε επιτυχώς")
    } catch (error) {
      this.progressSpinnerDialogService.end();
    }
  }

  // async editReceiptDialog(item) {
  //   const dialogConfig = new MatDialogConfig();
  //   dialogConfig.data = item;
  //   dialogConfig.width = "350px" ;
  //   const receiptDialog = this.receiptDialog.open(AddReceiptDialogComponent, dialogConfig);

  //   receiptDialog.afterClosed().subscribe(result => {
  //     if (result) {
  //       this.updateReceipt(result)
  //     }
  //   });
  // }

  // async updateReceipt(data) {
  //   try {
  //     let body = data
  //     this.progressSpinnerDialogService.show();
  //     await this.commonService.putRequest(`document/${data.id}`, body)
  //     this.progressSpinnerDialogService.end();
  //     await this.loadReceiptData()
  //   } catch (error) {
  //     this.progressSpinnerDialogService.end();
  //   }
  // }

  async newInvoice() {
    const invoiceDialog = this.invoiceDialog.open(InvoiceDialogComponent, {width: "500px" });

    invoiceDialog.afterClosed().subscribe(result => {
      if (result) {
        this.addInvoice(result)
      }
    });
  }

  async addInvoice(data) {
    try {
      let body = data
      this.progressSpinnerDialogService.show();
      await this.commonService.postRequest('document', body)
      this.progressSpinnerDialogService.end();
      await this.loadInvoiceData()
      this.toastMessagesService.toastSuccessMessage("Το τιμολόγιο αποθηκεύτηκε επιτυχώς")
    } catch (error) {
      this.progressSpinnerDialogService.end();
    }
  }

  async checkTab(event) {
    if (event.index === 1) {
      await this.loadInvoiceData();
    } else if (event.index === 0) {
      await this.loadReceiptData();
    }
  }

  async openInvoice(item) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = item;
    dialogConfig.width = "450px" ;
    const invoiceDialog = this.invoiceDialog.open(InvoiceDisplayDialogComponent, dialogConfig);

    invoiceDialog.afterClosed().subscribe(result => {

    });
  }

  async changePasswordDialog() {
    const changePasswordDialog = this.passwordDialog.open(ChangePasswordDialogComponent,  );

    changePasswordDialog.afterClosed().subscribe(result => {
      if (result) {
        this.changePassword(result)
      }
    });
  }
}
