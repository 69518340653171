<div>
    <h1 mat-dialog-title class="header">
        <span style="font-size: larger; margin-right: 30px;"> Εισαγωγή νέου Τιμολογίου</span>
        <mat-icon  (click)="closeDialog()" style="cursor: pointer;">close</mat-icon>
    </h1>
</div>

<div mat-dialog-content>
    <form [formGroup]="form">
        <div style="padding: 1px;" fxLayout="row" fxLayoutAlign="start center">
            <div fxLayout="column" fxFlex="35">
                <strong>Όνομα Εταιρείας:</strong>
            </div>
            <div fxLayout="column" fxFlex="65">
                <mat-form-field>
                    <mat-label>Όνομα Εταιρείας</mat-label>
                    <input [maxlength]="45" formControlName="company_name" placeholder="Ονομα Εταιρείας"  matInput required [(ngModel)]="company_name"/>
                </mat-form-field>
            </div>
        </div>
        <div style="padding: 1px;" fxLayout="row" fxLayoutAlign="start center">
            <div fxLayout="column" fxFlex="35">
                <strong>Επάγγελμα:</strong>
            </div>
            <div fxLayout="column" fxFlex="65">
                <mat-form-field>
                    <mat-label>Επάγγελμα</mat-label>
                    <input [maxlength]="45" formControlName="occupation" placeholder="Επάγγελμα"  matInput required [(ngModel)]="occupation"/>
                </mat-form-field>
            </div>
        </div>
        <div style="padding: 1px;" fxLayout="row" fxLayoutAlign="start center">
            <div fxLayout="column" fxFlex="35">
                <strong>Διεύθυνση 1:</strong>
            </div>
            <div fxLayout="column" fxFlex="65">
                <mat-form-field>
                    <mat-label>Διεύθυνση 1</mat-label>
                    <input [maxlength]="45" formControlName="address1" placeholder="Διεύθυνση 1"  matInput required [(ngModel)]="address1"/>
                </mat-form-field>
            </div>
        </div>
        <div style="padding: 1px;" fxLayout="row" fxLayoutAlign="start center">
            <div fxLayout="column" fxFlex="35">
                <strong>Διεύθυνση 2:</strong>
            </div>
            <div fxLayout="column" fxFlex="65">
                <mat-form-field>
                    <mat-label>Διεύθυνση 2</mat-label>
                    <input [maxlength]="45" formControlName="address2" placeholder="Διεύθυνση 2"  matInput required [(ngModel)]="address2"/>
                </mat-form-field>
            </div>
        </div>
        <div style="padding: 1px;" fxLayout="row" fxLayoutAlign="start center">
            <div fxLayout="column" fxFlex="35">
                <strong>Πόλη:</strong>
            </div>
            <div fxLayout="column" fxFlex="65">
                <mat-form-field>
                    <mat-label>Πόλη</mat-label>
                    <input [maxlength]="45" formControlName="city" placeholder="Πόλη"  matInput required [(ngModel)]="city"/>
                </mat-form-field>
            </div>
        </div>
        <div style="padding: 1px;" fxLayout="row" fxLayoutAlign="start center">
            <div fxLayout="column" fxFlex="35">
                <strong>Περιοχή:</strong>
            </div>
            <div fxLayout="column" fxFlex="65">
                <mat-form-field>
                    <mat-label>Περιοχή</mat-label>
                    <input placeholder="Περιοχή" formControlName="area" matInput required [(ngModel)]="area"/>
                </mat-form-field>
            </div>
        </div>
        <div style="padding: 1px;" fxLayout="row" fxLayoutAlign="start center">
            <div fxLayout="column" fxFlex="35">
                <strong>Τ.Κ:</strong>
            </div>
            <div fxLayout="column" fxFlex="65">
                <mat-form-field>
                    <mat-label>Τ.Κ</mat-label>
                    <input placeholder="Τ.Κ" formControlName="postal_code" matInput required [(ngModel)]="postal_code"/>
                    <mat-error *ngIf="form.get('postal_code')?.value && form.get('postal_code')?.invalid">Εισάγετε ένα έγκυρο T.K.</mat-error>
                </mat-form-field>
            </div>
        </div>
        <div style="padding: 1px;" fxLayout="row" fxLayoutAlign="start center">
            <div fxLayout="column" fxFlex="35">
                <strong>Χώρα:</strong>
            </div>
            <div fxLayout="column" fxFlex="65">
                <mat-form-field>
                    <mat-label>Χώρα</mat-label>
                    <input [maxlength]="45" formControlName="country" placeholder="Χώρα"  matInput required [(ngModel)]="country"/>
                </mat-form-field>
            </div>
        </div>
        <div style="padding: 1px;" fxLayout="row" fxLayoutAlign="start center">
            <div fxLayout="column" fxFlex="35">
                <strong>Email:</strong>
            </div>
            <div fxLayout="column" fxFlex="65">
                <mat-form-field>
                    <mat-label>Email</mat-label>
                    <input placeholder="Email" formControlName="invoice_email" matInput required [(ngModel)]="invoice_email"/>
                    <mat-error *ngIf="form.get('invoice_email')?.value && form.get('invoice_email')?.invalid">Εισάγετε ένα έγκυρο email.</mat-error>
                </mat-form-field>
            </div>
        </div>
        <div style="padding: 1px;" fxLayout="row" fxLayoutAlign="start center">
            <div fxLayout="column" fxFlex="35">
                <strong>Α.Φ.Μ:</strong>
            </div>
            <div fxLayout="column" fxFlex="65">
                <mat-form-field>
                    <mat-label>Α.Φ.Μ</mat-label>
                    <input placeholder="Α.Φ.Μ" formControlName="vat_number" matInput required [(ngModel)]="vat_number"/>
                </mat-form-field>
            </div>
        </div>
        <div style="padding: 1px;" fxLayout="row" fxLayoutAlign="start center">
            <div fxLayout="column" fxFlex="35">
                <strong>Δ.Ο.Υ:</strong>
            </div>
            <div fxLayout="column" fxFlex="65">
                <mat-form-field>
                    <mat-label>Δ.Ο.Υ</mat-label>
                    <input placeholder="Δ.Ο.Υ" formControlName="tax_office" matInput required [(ngModel)]="tax_office"/>
                </mat-form-field>
            </div>
        </div>
    </form>
</div>

<div mat-dialog-actions align="end">
    <button [disabled]="!form.valid" (click)="submit()" class="custom-button" mat-raised-button>Αποθήκευση</button>
</div>
 