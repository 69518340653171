import {Component, Inject, OnInit} from '@angular/core';
import {ToastMessagesService} from '../../services/toast-messages.service';
import {CommonService} from '../../services/common.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {UserService} from '../../services/users/user.service';
import {ProgressSpinnerDialogService} from '../../services/progress-spinner-dialog.service';

@Component({
  selector: 'app-contact-dialog',
  templateUrl: './contact-dialog.component.html',
  styleUrls: ['./contact-dialog.component.scss']
})
export class ContactDialogComponent implements OnInit {

  user: any;
  hide = true;
  error: boolean;
  loading = false;
  quick = false;
  contactData : any;

  constructor(private toastMessagesService: ToastMessagesService,
              private commonService: CommonService,
              public dialogRef: MatDialogRef<ContactDialogComponent>,
              public router: Router,
              private userService: UserService,
              private progressSpinnerDialogService: ProgressSpinnerDialogService,
              @Inject(MAT_DIALOG_DATA) data) {
    if (data) {
      this.quick = data.quick;
    }
  }

  async ngOnInit(){
    this.contactData = await this.commonService.getRequest('communication');
  }

  closeDialog(data: any = false) {
    this.dialogRef.close(data);
  }

}
