<div>
  <h1 mat-dialog-title class="header">
    <span *ngIf="!update" style="font-size: larger; margin-right: 30px;"> Εισαγωγή νέας απόδειξης</span>
    <span *ngIf="update" style="font-size: larger; margin-right: 30px;"> Επεξεργασία απόδειξης</span>
    <mat-icon (click)="closeDialog()" style="cursor: pointer;">close</mat-icon>
  </h1>
 
  <form [formGroup]="form">
    
    <div style="padding: 1px;" fxLayout="row" fxLayoutAlign="start center">
      <div fxLayout="column" fxFlex="35">
          <strong>Όνομα:</strong>
      </div>
      <div fxLayout="column" fxFlex="65">
          <mat-form-field>
              <mat-label>Όνομα</mat-label>
              <input [maxLength]="45" formControlName="first_name" matInput [(ngModel)]="first_name" required/>
          </mat-form-field>
      </div>
    </div>
    <div style="padding: 1px;" fxLayout="row" fxLayoutAlign="start center">
      <div fxLayout="column" fxFlex="35">
          <strong>Επώνυμο:</strong>
      </div>
      <div fxLayout="column" fxFlex="65">
          <mat-form-field>
              <mat-label>Επώνυμο</mat-label>
              <input [maxLength]="45" formControlName="last_name" matInput [(ngModel)]="last_name" required/>
          </mat-form-field>
      </div>
    </div>
    <div style="padding: 1px;" fxLayout="row" fxLayoutAlign="start center">
      <div fxLayout="column" fxFlex="35">
          <strong>Email:</strong>
      </div>
      <div fxLayout="column" fxFlex="65">
        <mat-form-field>
          <mat-label>Email</mat-label>
          <input [maxLength]="60" formControlName="email" matInput [(ngModel)]="email" required/>
          <mat-error *ngIf="form.get('email')?.value && form.get('email')?.invalid">Εισάγετε ένα έγκυρο email.</mat-error>
        </mat-form-field>
      </div>
    </div>
  </form>

  <div mat-dialog-actions align="end">
    <button [disabled]="!form.valid" (click)="submit()" class="custom-button" mat-raised-button>Αποθήκευση</button>
  </div>

</div>

