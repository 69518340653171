<h3 mat-dialog-title><div class="header" style="margin-bottom: 15px">
  <span style="font-size: larger">Στοιχεία Επικοινωνίας Φωτοτυπικού Κέντρου</span>
  <mat-icon  (click)="closeDialog()" style="cursor: pointer;">close</mat-icon>
</div></h3>

<div class="container" mat-dialog-content>
</div>
<div *ngIf="contactData">
  <div fxLayout="row" style="margin: 15px">
    <div fxLayout="column" class="info-color" style="height: 24px">
      <mat-icon>home</mat-icon>
    </div>
    <div fxLayout="column" style="height: 24px; margin-top: 5px; margin-left: 5px">
      {{contactData.address}}, {{contactData.city}} {{contactData.postal_code}}
    </div>
  </div>
  <div fxLayout="row" style="margin: 15px">
    <div fxLayout="column" class="info-color" style="height: 24px">
      <mat-icon>phone</mat-icon>
    </div>
    <div fxLayout="column" style="height: 24px; margin-top: 5px; margin-left: 5px">
      {{contactData.phone_number}}
    </div>
  </div>
  <div fxLayout="row" style="margin: 15px">
    <div fxLayout="column" class="info-color" style="height: 24px">
      <mat-icon>email</mat-icon>
    </div>
    <div fxLayout="column" style="height: 24px; margin-top: 5px; margin-left: 5px">
      {{contactData.email}}
    </div>
  </div>
</div>

