<div fxLayout="column" class="content" *ngIf="dataLoaded">
  <div *ngIf="+mode !== 2 && +mode !== 3 && +mode !== 4" ngStyle.lt-sm=" ">
    <div fxLayout="row" fxLayoutAlign="center" class="mat-title">
      Πληροφορίες
    </div>
    <mat-divider style="margin: 1.5%"></mat-divider>

    <div class="center">
      <div fxLayoutAlign="start">
        <mat-icon class="icon-config badge-color" class="badge-color" style="margin-right: 5px;">info</mat-icon>
        <span style="margin-top: 3px;">
          Εδώ μπορείτε να δείτε και να επεξεργαστείτε τις πληροφορίες χρήστη σας.
        </span>
      </div>
    </div>

    <div class="profile-info-container">
      <div fxHide.lt-sm fxLayout="row wrap" fxLayout.lt-md="column" fxLayoutGap="45px" fxLayoutAlign.xs="start" fxLayoutAlign.md="start"
        fxLayoutAlign.gt-xs="space-evenly center" style="font-size: medium; padding-top: 2%;">
        <div fxLayout="column" fxLayoutGap="10px">
          <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center center">
            </div>
          </div>

        <div fxLayout="column" fxLayoutGap="10px">
          <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center center">
            <div style="width: 350px;">

            </div>
          </div>
        </div>
      </div>

      <div fxHide.lt-sm fxLayout="row wrap" fxLayout.lt-md="column" fxLayoutGap="45px" fxLayoutAlign.xs="start" fxLayoutAlign.md="start"
          fxLayoutAlign.gt-xs="space-evenly center" style="font-size: medium">
        <div fxLayout="column" fxLayoutGap="10px">
          <div fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="start center center">
            <div style="width: 100px;">Όνομα:</div>
            <mat-form-field fxFlex>
              <label>
                <input matInput [(ngModel)]="user.first_name" [readonly]="!edit">
              </label>
              <mat-icon *ngIf="edit" style="cursor: pointer;" (click)="user.first_name = ''"
              matSuffix>clear</mat-icon>
            </mat-form-field>
          </div>
          <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center center">
            <div style="width: 100px">Επώνυμο:</div>
            <mat-form-field fxFlex>
              <label>
                <input matInput [(ngModel)]="user.last_name" [readonly]="!edit">
              </label>
              <mat-icon *ngIf="edit" style="cursor: pointer;" (click)="user.last_name = ''"
              matSuffix>clear</mat-icon>
            </mat-form-field>
          </div>
          <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center center">
            <div style="width: 100px;">Email:</div>
            <mat-form-field fxFlex>
              <label>
                <input matInput [(ngModel)]="user.email" [readonly]="!edit">
              </label>
              <mat-icon *ngIf="edit" style="cursor: pointer;" (click)="user.email = ''"
              matSuffix>clear</mat-icon>
            </mat-form-field>
          </div>
        </div>

        <div fxLayout="column" fxLayoutGap="10px">
          <div fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="start center center">
            <div style="width: 100px;">Τηλέφωνο:</div>
            <mat-form-field fxFlex>
              <label>
                <input matInput maxlength="10" minlength="10" [(ngModel)]="user.phone_number" [readonly]="!edit">
              </label>
              <mat-icon *ngIf="edit" style="cursor: pointer;" (click)="user.phone_number = ''"
              matSuffix>clear</mat-icon>
            </mat-form-field>
          </div>
          <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center center">
            <div style="width: 100px">Διεύθυνση:</div>
            <mat-form-field fxFlex>
              <label>
                <input matInput [(ngModel)]="user.address" [readonly]="!edit">
              </label>
              <mat-icon *ngIf="edit" style="cursor: pointer;" (click)="user.address = ''"
              matSuffix>clear</mat-icon>
            </mat-form-field>
          </div>
          <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center center">
            <div style="width: 100px">ΤΚ:</div>
            <mat-form-field fxFlex>
              <label>
                <input matInput maxlength="5" minlength="5" [(ngModel)]="user.postal_code" [readonly]="!edit">
              </label>
              <mat-icon *ngIf="edit" style="cursor: pointer;" (click)="user.postal_code = ''"
              matSuffix>clear</mat-icon>
            </mat-form-field>
          </div>
        </div>

      </div>
      <div fxHide.gt-xs>
        <div style="width: 90%; margin-left: 30px;">
          <div fxLayout="column" fxLayoutGap="10px">
            <div fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="start center center">
              <div style="width: 100px;">Όνομα:</div>
              <mat-form-field fxFlex>
                <label>
                  <input matInput [(ngModel)]="user.first_name" [readonly]="!edit">
                </label>
                <mat-icon *ngIf="edit" style="cursor: pointer;" (click)="user.first_name = ''"
                matSuffix>clear</mat-icon>
              </mat-form-field>
            </div>
            <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center center">
              <div style="width: 100px">Επώνυμο:</div>
              <mat-form-field fxFlex>
                <label>
                  <input matInput [(ngModel)]="user.last_name" [readonly]="!edit">
                </label>
                <mat-icon *ngIf="edit" style="cursor: pointer;" (click)="user.last_name = ''"
                matSuffix>clear</mat-icon>
              </mat-form-field>
            </div>
            <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center center">
              <div style="width: 100px;">Email:</div>
              <mat-form-field fxFlex>
                <label>
                  <input matInput [(ngModel)]="user.email" [readonly]="!edit">
                </label>
                <mat-icon *ngIf="edit" style="cursor: pointer;" (click)="user.email = ''"
                matSuffix>clear</mat-icon>
              </mat-form-field>
            </div>
          </div>

          <div fxLayout="column" fxLayoutGap="10px">
            <div fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="start center center">
              <div style="width: 100px;">Τηλέφωνο:</div>
              <mat-form-field fxFlex>
                <label>
                  <input matInput maxlength="5" minlength="5" [(ngModel)]="user.phone_number" [readonly]="!edit">
                </label>
                <mat-icon *ngIf="edit" style="cursor: pointer;" (click)="user.phone_number = ''"
                matSuffix>clear</mat-icon>
              </mat-form-field>
            </div>
            <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center center">
              <div style="width: 100px">Διεύθυνση:</div>
              <mat-form-field fxFlex>
                <label>
                  <input matInput [(ngModel)]="user.address" [readonly]="!edit">
                </label>
                <mat-icon *ngIf="edit" style="cursor: pointer;" (click)="user.address = ''"
                matSuffix>clear</mat-icon>
              </mat-form-field>
            </div>
            <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center center">
              <div style="width: 100px">ΤΚ:</div>
              <mat-form-field fxFlex>
                <label>
                  <input matInput maxlength="5" minlength="5" [(ngModel)]="user.postal_code" [readonly]="!edit">
                </label>
                <mat-icon *ngIf="edit" style="cursor: pointer;" (click)="user.postal_code = ''"
                matSuffix>clear</mat-icon>
              </mat-form-field>
            </div>
          </div>

        </div>
      </div>
      <div style="margin-top:1%;" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="45px" fxLayoutAlign.xs="start">
        <button *ngIf="!edit" style="width: 220px;" class="action-btn custom-button" mat-raised-button (click)="editing()">Επεξεργασία</button>
        <button *ngIf="edit" style="width: 220px;" class="action-btn custom-button" mat-raised-button [disabled]="!isUserValid()" (click)="editInfo()">Αποθήκευση</button>
        <button *ngIf="edit" mat-icon-button style="margin: 10px" (click)="cancelEdit()">
          <mat-icon>clear</mat-icon>
        </button>
      </div>
      <div style="margin-top: 1.5%;">
        <div fxLayoutAlign="center center center">
          <button style="width: 220px;" mat-raised-button (click)="changePasswordDialog()" class="action-btn  custom-button">Αλλαγή κωδικού πρόσβασης</button>
        </div>
        <!-- <div class="settings-containter">
          <div fxLayout="column" fxLayoutAlign.lt-md="center center center" fxLayoutGap="10px">
            <div>
              <mat-form-field [ngStyle.lt-md]="{'width':'300px'}">
                <mat-label>Νέο συνθηματικό</mat-label>
                <input matInput [(ngModel)]="newPassword1"/>
              </mat-form-field>
            </div>
            <div>
              <mat-form-field [ngStyle.lt-md]="{'width':'300px'}">
                <mat-label>Επανάληψη συνθηματικού</mat-label>
                <input matInput [(ngModel)]="newPassword2"/>
              </mat-form-field>
            </div>
            <div class="center">
              <button mat-raised-button class="action-btn custom-button" [disabled]="!isPasswordValid()" (click)="changePassword()">Αποθήκευση</button>
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </div>


  <div *ngIf="+mode === 2" style="overflow-y: auto;height: 100%;">
    <div *ngIf="loading_prints">
      <div class="loading">
        <mat-spinner class="transparent"></mat-spinner>
      </div>
    </div>
    <div *ngIf="dataLoaded" class="profile-prints">
      <app-print-order-details [profile]="true" title="Οι εκτυπώσεις μου" *ngIf="printsTable.length > 0" [info]="printsTable"></app-print-order-details>
    </div>
  </div>

  <div *ngIf="+mode === 3">
    <div fxLayoutAlign="center center center" class="mat-title">Αλλαγή κωδικού πρόσβασης</div>

    <div class="settings-containter">
      <div fxLayout="column" fxLayoutAlign.lt-md="center center center" fxLayoutGap="10px">
        <div>
          <mat-form-field [ngStyle.lt-md]="{'width':'300px'}">
            <mat-label>Νέο συνθηματικό</mat-label>
            <input matInput [(ngModel)]="newPassword1"/>
          </mat-form-field>
        </div>
        <div>
          <mat-form-field [ngStyle.lt-md]="{'width':'300px'}">
            <mat-label>Επανάληψη συνθηματικού</mat-label>
            <input matInput [(ngModel)]="newPassword2"/>
          </mat-form-field>
        </div>
        <div>
          <button mat-raised-button class="custom-button" [disabled]="!isPasswordValid()" (click)="changePassword()">Αποθήκευση</button>
        </div>
      </div>
    </div>

  </div>

  <div *ngIf="+mode === 4">
    <div fxLayoutAlign="center center center" style="padding-bottom: 5px;" class="mat-title">Τρόποι τιμολόγησης</div>
    <mat-divider></mat-divider>
    <mat-tab-group (selectedTabChange)="checkTab($event)" [dynamicHeight]="true">
      <!-- <mat-tab label="Οι αποδείξεις μου">
        <div class="center">
          <div fxLayoutAlign="start">
            <mat-icon class="icon-config badge-color" class="badge-color" style="margin-right: 5px; margin-top: 10px">info</mat-icon>
            <span style="margin-top: 10px;">
              Εδώ μπορείτε να δείτε τις αποδείξεις σας.
            </span>
          </div>
        </div>

        <div fxLayout="row" fxLayoutAlign="start center center" style="margin: 20px;">
          <div ><button style="width: 200px;" class="custom-button" (click)="newReceipt()" mat-raised-button>Εισαγωγή απόδειξης</button></div>
        </div>

        <div fxLayout="column" [ngStyle.lt-sm]="{'height': '70%', 'width':'90%'}">
          <div fxLayout="row wrap" fxLayout.lt-sm="column"  fxLayoutAlign="start center center">
            <mat-card class="mat-elevation-z5 receipt-card-config" [ngStyle.lt-md]="{'width':'80%'}" *ngFor="let item of receipts; let index = index;">
              <div style="padding: 2px;" fxLayout="row" fxLayoutAlign="start center">
                <div fxLayout="column" fxFlex="45">
                    <strong>Όνομα:</strong>
                </div>
                <div fxLayout="column" fxFlex="55">
                    {{item.first_name}}
                </div>
              </div>
              <div style="padding: 2px;" fxLayout="row" fxLayoutAlign="start center">
                <div fxLayout="column" fxFlex="45">
                    <strong>Επώνυμο:</strong>
                </div>
                <div fxLayout="column" fxFlex="55">
                    {{item.last_name}}
                </div>
              </div>
              <div style="padding: 2px;" fxLayout="row" fxLayoutAlign="start center">
                <div fxLayout="column" fxFlex="45">
                    <strong>Email:</strong>
                </div>
                <div fxLayout="column" fxFlex="55">
                    {{item.email}}
                </div>
              </div>

            </mat-card>
          </div>
        </div>
      </mat-tab> -->
      <mat-tab label="Τα τιμολόγιά μου">

        <div class="center">
          <div fxLayoutAlign="start">
            <mat-icon class="icon-config badge-color" class="badge-color" style="margin-right: 5px; margin-top: 10px">info</mat-icon>
            <span style="margin-top: 10px;">
              Εδώ μπορείτε να δείτε τα τιμολόγιά σας.
            </span>
          </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="start center center" style="margin: 20px;">
          <div ><button style="width: 200px; margin-left: 25px;" class="custom-button" (click)="newInvoice()" mat-raised-button>Εισαγωγή τιμολογίου</button></div>
        </div>

        <div fxLayout="column" [ngStyle.lt-sm]="{'height': '70%', 'width':'90%'}">
          <div fxLayout="row wrap" fxLayout.lt-sm="column"  fxLayoutAlign="start center center">
            <mat-card class="mat-elevation-z5 card-config" [ngStyle.lt-md]="{'width':'80%'}" *ngFor="let item of invoices; let index = index;">
              <div fxLayoutAlign="space-between center" style="margin: 5px">
              </div>
              <div style="padding: 2px;" fxLayout="row" fxLayoutAlign="start center">
                <div fxLayout="column" fxFlex="45">
                    <strong>Όνομα εταιρείας:</strong>
                </div>
                <div fxLayout="column" fxFlex="55">
                    {{item.company_name}}
                </div>
              </div>

              <div style="padding: 2px;" fxLayout="row" fxLayoutAlign="start center">
                <div fxLayout="column" fxFlex="45">
                    <strong>Επάγγελμα:</strong>
                </div>
                <div fxLayout="column" fxFlex="55">
                    {{item.occupation}}
                </div>
              </div>

              <div style="padding: 2px;" fxLayout="row" fxLayoutAlign="start center">
                <div fxLayout="column" fxFlex="45">
                    <strong>Διεύθυνση:</strong>
                </div>
                <div fxLayout="column" fxFlex="55">
                    {{item.address1}}
                </div>
              </div>

              <div style="padding: 2px;" fxLayout="row" fxLayoutAlign="start center">
                <div fxLayout="column" fxFlex="45">
                    <strong>Πόλη:</strong>
                </div>
                <div fxLayout="column" fxFlex="55">
                    {{item.city}}
                </div>
              </div>

              <div style="padding: 2px;" fxLayout="row" fxLayoutAlign="start center">
                <div fxLayout="column" fxFlex="45">
                    <strong>Περιοχή:</strong>
                </div>
                <div fxLayout="column" fxFlex="55">
                    {{item.area}}
                </div>
              </div>

              <div fxLayoutAlign="space-between center" style="margin: 5px">
                <mat-icon matTooltip="Δείτε περισσότερα" (click)="openInvoice(item)">visibility</mat-icon>
              </div>
            </mat-card>
          </div>
        </div>

      </mat-tab>
    </mat-tab-group>
  </div>
</div>
