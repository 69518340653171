import {Component, Inject, OnInit} from '@angular/core';

import {MatDialogRef} from '@angular/material/dialog';
import { ProgressSpinnerDialogService } from 'src/app/services/progress-spinner-dialog.service';
  

@Component({
  selector: 'app-confirm-payment-dialog',
  templateUrl: './confirm-payment-dialog.component.html',
  styleUrls: ['./confirm-payment-dialog.component.scss']
})
export class ConfirmPaymentDialogComponent implements OnInit {
 
  constructor(public dialogRef: MatDialogRef<ConfirmPaymentDialogComponent>,
              private progressSpinnerDialogService: ProgressSpinnerDialogService) {
 
  }

  async ngOnInit(){
    // this.dialogRef.updatePosition({top: '80px'});
    // this.dialogRef.updateSize('40%');
    // this.dialogRef.disableClose = true;
  }

  closeDialog(data: any = false) {
    this.dialogRef.close(data);
  }

  async downloadPDF(): Promise<any> {
    // this.progressSpinnerDialogService.show();
    // const attachments = await this.paymentService.downloadPDF(this.data.id);
    // this.reportService.downloadReport(attachments);
    // this.progressSpinnerDialogService.end();
  }
}
