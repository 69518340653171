
<div>
    <h1 mat-dialog-title>
        <div class="header">
            <span style="font-size: larger; margin-right: 30px;"> Ανάκτηση κωδικού πρόσβασης</span>
            <mat-icon  (click)="closeDialog()" style="cursor: pointer;">close</mat-icon>
        </div>
    </h1>
    <div class="container"> 
        <div class="fw">
           <div class="mat-subheading-1" fxLayoutAlign="start">
            <mat-icon class="icon-config info-color" style="margin-right: 5px; ">info</mat-icon>Συμπληρώστε τη διεύθυνση email σας. 
            </div>
        </div>
        <form [formGroup]="form" style="width: 100%">
            <div fxLayout="row" fxLayoutAlign="space-between" style="width: 100%; margin-top: 20px;">
                <div fxLayout="column" fxFlex="10">
                    <mat-icon class="info-color" style="margin-top: 10px;">email</mat-icon>
                </div>
                <div fxLayout="column" fxFlex="90">
                    <mat-form-field  class="fw">
                        <mat-label>Διεύθυνση email</mat-label>
                        <input matInput formControlName="email"/>
                        <mat-error *ngIf="form.get('email')?.value && form.get('email')?.invalid">Εισάγετε ένα έγκυρο email.</mat-error>
                    </mat-form-field>
                </div>
            </div>
        </form>
    </div>
</div>
<div mat-dialog-actions align="end">
    <button mat-flat-button class="custom-button" [disabled]="form.get('email')?.invalid" (click)="putEmail()">Επιβεβαίωση</button>
</div>

