import {Component, Inject, OnInit} from '@angular/core';

import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { ProgressSpinnerDialogService } from 'src/app/services/progress-spinner-dialog.service';
  

@Component({
  selector: 'app-action-completion-dialog',
  templateUrl: './action-completion-dialog.component.html',
  styleUrls: ['./action-completion-dialog.component.scss']
})
export class ActionCompletionDialogComponent implements OnInit {
  public message: string;
  public title: string;

  constructor(public dialogRef: MatDialogRef<ActionCompletionDialogComponent>,
              private progressSpinnerDialogService: ProgressSpinnerDialogService,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    this.message = this.data.message;
    this.title = this.data.title;
  }

  async ngOnInit(){
    // this.dialogRef.updatePosition({top: '80px'});
    // this.dialogRef.updateSize('40%');
    // this.dialogRef.disableClose = true;
  }

  closeDialog(data: any = false) {
    this.dialogRef.close(data);
  }

}
