<h1 mat-dialog-title>
  <div class="header">
    <span style="font-size: larger; padding-right: 20px;">{{title}}</span>
    <mat-icon (click)="closeDialog()" style="cursor: pointer;">close</mat-icon>
  </div>
</h1>

<div mat-dialog-content>
  <div class="default-message">
    {{message}}
  </div>
</div>
