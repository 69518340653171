import { CommonService } from './../../services/common.service';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss']
})
export class TermsComponent implements OnInit {

  logout = false;
  preview = false;
  terms;

  constructor(@Inject(MAT_DIALOG_DATA) data, private commonService: CommonService) {

    if (data.logout) {
      this.logout = true;
    }

    if (data.preview) {
      this.preview = true;
    }
  }

  async ngOnInit() {
    try {
      // const response = await this.commonService.getRequest('settings/terms')
      this.terms = '';
    } catch (error) {

    }
  }

}


