import {Component, OnInit} from '@angular/core';
import {UserService} from '../../services/users/user.service';
import {Router} from '@angular/router';
import {ToastMessagesService} from '../../services/toast-messages.service';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-activate-account',
  templateUrl: './activate-account.component.html',
  styleUrls: ['./activate-account.component.scss']
})
export class ActivateAccountComponent implements OnInit {
  id: number;

  constructor(private userService: UserService, private router: Router, public toastMessagesService: ToastMessagesService, private route: ActivatedRoute) {
 
  }

  async ngOnInit() {
    try {

      const userId = this.route.snapshot.paramMap.get('id');
      await this.userService.activateUser(userId);
      this.toastMessagesService.toastSuccessMessage('Ο λογαριασμός σας ενεργοποιήθηκε με επιτυχία.');
      await this.router.navigate(['/']);
    } catch (e) {
      console.log(e);
      this.toastMessagesService.toastErrorMessage(e.error.message);
      await this.router.navigate(['/']);
    }

  }

}
