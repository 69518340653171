import { Component, OnInit } from '@angular/core';
import {PrintService} from '../../services/print.service';
import {ProgressSpinnerDialogService} from '../../services/progress-spinner-dialog.service';


@Component({
  selector: 'app-pending-order-details',
  templateUrl: './pending-order-details.component.html',
  styleUrls: ['./pending-order-details.component.scss']
})
export class PendingOrderDetailsComponent implements OnInit {
  orders = [];
  dataLoaded: boolean;
  data: any;

  constructor(private printService: PrintService, private progressSpinnerDialogService: ProgressSpinnerDialogService) {
    this.dataLoaded = false;
  }

  async ngOnInit() {
    try {
      this.progressSpinnerDialogService.show();
      const orders = await this.printService.getPendingOrders();
      for (const data of orders) {
        this.orders.push(data);
      }
      this.dataLoaded = true;
      this.progressSpinnerDialogService.end();
    } catch (e) {
      this.dataLoaded = true;
      this.progressSpinnerDialogService.end();
      console.log(e);
    }
  }


}
