import {Injectable} from '@angular/core';
import {CommonService} from '../common.service';
import {UserInfo} from '../../interfaces/users';
import {Router} from '@angular/router';
import {ToastMessagesService} from '../toast-messages.service';
import { ProgressSpinnerDialogService } from '../progress-spinner-dialog.service';


@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private commonService: CommonService,
    private router: Router,
    public toastMessagesService: ToastMessagesService,
    private progressSpinnerDialogService: ProgressSpinnerDialogService) {
  }

  async userLogin(username, password, redirect = true) {
    try {
      const user = {
        username,
        password,
        admin_request: 0
      };
      const result = await this.commonService.postRequest(`login`, user);
      // console.log(result);
      localStorage.setItem('token', result);
      const me = await this.commonService.getRequest(`user/me`);
      localStorage.setItem('user', JSON.stringify(me));

      this.progressSpinnerDialogService.end();

      if (redirect) {
        await this.router.navigate(['/home/main']);
      }

      return true;
    } catch (e) {

      if (e.status === 504) {
        this.toastMessagesService.toastErrorMessage("Δεν υπάρχει σύνδεση στο διαδίκτυο.");
      } else {
        this.toastMessagesService.toastErrorMessage(e.error.message);
      }
      
    }
  }

  async userLogout(redirect = true, expired = false) {
    localStorage.removeItem('user');
    localStorage.removeItem('token');

    if (expired) {
      this.toastMessagesService.toastErrorMessage('Έληξε η περίοδος της σύνδεσής σας, παρακαλώ συνδεθείτε ξανά.')
    }

    if (redirect) {
      await this.router.navigateByUrl('/');
    }
  }

  checkLoggedUser() {
    let user = localStorage.getItem('user')

    if (user !== undefined) {
      return JSON.parse(user);
    }
    
    return false;
  }

  async validateUser(expired = true) {
    try {
      // if (localStorage.getItem('user')) {
      //   return JSON.parse(localStorage.getItem('user'));
      // } else {
      //   return null;
      // }
     const user = await this.commonService.getRequest(`user/me`);
 
     return user;
    } catch (e) {

      if (e.status === 401 && expired) {
        this.userLogout(true, true)
      }
      throw e;
    }
  }

  async updateUserInfo(user) {
    try {
      const userUpdated = await this.commonService.putRequest(`user`, user);
      localStorage.setItem('user', JSON.stringify(user));
      return userUpdated;
    } catch (e) {
      throw e;
    }
  }

  async updateUserPassword(user) {
    try {
      const userUpdated = await this.commonService.putRequest(`user/password`, user);
      // localStorage.setItem('user', JSON.stringify(user));
      return userUpdated;
    } catch (e) {
      throw e;
    }
  }

  async activateUser(id) {
    try {
      const item = {
        id: id
      };
      const userActivated = await this.commonService.putRequest(`user/activate/${id}`, item);
      return userActivated;
    } catch (e) {
      throw e;
    }
  }

  async registerUser(user) {
    try {
      const result = await this.commonService.postRequest(`user`, user);
      return result;
     } catch (e) {
      throw e;
    }
  }

  /**
   * Description: returns the logged user
   * @returns 
   */
  async getLoggedUser() {

    let user = localStorage.getItem('user')

    if (user) {
      return JSON.parse(user)
    }

    return false
  }
}
