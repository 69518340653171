<div class="main-div">
  <mat-toolbar class="app-toolbar">
    <mat-toolbar-row fxLayoutAlign="space-between center">
      <div [ngStyle.lt-sm]="{'font-size':'small'}">
        <app-header></app-header>
      </div>
  <!--    <div id="navBar" fxLayout="row" fxHide.xs>-->
  <!--      <a routerLinkActive="active" routerLink="/home/main">-->
  <!--        <button mat-button>Αρχική</button>-->
  <!--      </a>-->
  <!--&lt;!&ndash;      <button mat-button [matMenuTriggerFor]="profileMenu" #menuTrigger="matMenuTrigger"&ndash;&gt;-->
  <!--&lt;!&ndash;              style="margin-bottom: 5px">Προφίλ</button>&ndash;&gt;-->
  <!--&lt;!&ndash;      <mat-menu #profileMenu="matMenu">&ndash;&gt;-->
  <!--&lt;!&ndash;        <span (mouseleave)="menuTrigger.closeMenu()">&ndash;&gt;-->
  <!--&lt;!&ndash;        <div fxLayout="column" fxLayoutGap="5px"  style="width: 200px; margin: 10px">&ndash;&gt;-->
  <!--&lt;!&ndash;          <div>Όνοματεπώνυμο:{{user[0].username}}</div>&ndash;&gt;-->
  <!--&lt;!&ndash;          <div>ΑΕΜ:{{user[0].profile.aem}}</div>&ndash;&gt;-->
  <!--&lt;!&ndash;          <div>Διεύθυνση:{{user[0].profile.address}}</div>&ndash;&gt;-->
  <!--&lt;!&ndash;          <div>Τηλέφωνο:{{user[0].profile.phone}}</div>&ndash;&gt;-->
  <!--&lt;!&ndash;          <div>Πόλη:{{user[0].profile.city}}</div>&ndash;&gt;-->
  <!--&lt;!&ndash;          <div>ΤΚ:{{user[0].profile.postalCode}}</div>&ndash;&gt;-->
  <!--&lt;!&ndash;        </div>&ndash;&gt;-->
  <!--&lt;!&ndash;        </span>&ndash;&gt;-->
  <!--&lt;!&ndash;      </mat-menu>&ndash;&gt;-->
  <!--      <a routerLinkActive="active" routerLink="/home/profile"> <button mat-button>Προφίλ</button></a>-->
  <!--     <a routerLinkActive="active" routerLink="/home/new-print"> <button mat-button>Νέα εκτύπωση</button></a>-->

      <div fxLayout="row" fxHide.sm>

        <button routerLinkActive="active-btn" class="text-btn" fxHide.xs mat-button routerLink="/home/new-print">
          <span>
            Νέα εκτύπωση
          </span>
        </button>


        <button routerLinkActive="active-btn" class="text-btn" fxHide.xs mat-button routerLink="/home/ready-order">
          <span>
            Οι εκτυπώσεις μου
          </span>
        </button>

        <!-- <button *ngIf="pending_order_counter > 0" -->
        <button [matBadge]="pending_order_counter"  matBadgeHidden="{{pending_order_counter <= 0}}"
        routerLinkActive="active-btn" class="text-btn" fxHide.xs mat-button routerLink="/home/pending-order">
          <span>
            Εκκρεμείς εκτυπώσεις
          </span>
        </button>
        <!-- <button
        *ngIf="pending_order_counter <= 0"
        routerLinkActive="active-btn" class="text-btn" fxHide.xs mat-button routerLink="/home/pending-order">
          <span>
            Εκκρεμείς εκτυπώσεις
          </span>
        </button> -->

        <!-- <button *ngIf="new_announcements_counter > 0" -->
        <button [matBadge]="new_announcements_counter"  matBadgeHidden="{{new_announcements_counter <= 0}}"
        routerLinkActive="active-btn" class="text-btn" fxHide.xs mat-button routerLink="/home/announcement">
          <span>
            Ανακοινώσεις
          </span>
        </button>
        <!-- <button
        *ngIf="new_announcements_counter <= 0"
        routerLinkActive="active-btn" class="text-btn" fxHide.xs mat-button routerLink="/home/announcement">
          <span>
            Ανακοινώσεις
          </span>
        </button> -->

        <button routerLinkActive="active-btn" fxHide.xs mat-icon-button routerLink="/home/main">
          <mat-icon matTooltip="Αρχική Σελίδα">home</mat-icon>
        </button>
        <button routerLinkActive="active-btn" fxHide.xs mat-icon-button routerLink="/home/profile">
          <mat-icon matTooltip="Το Προφίλ μου">account_circle</mat-icon>
        </button>
        <button routerLinkActive="active-btn" fxHide.xs mat-icon-button (click)="showContactData()">
          <mat-icon matTooltip="Στοιχεία Επικοινωνίας Φωτοτυπικού Κέντρου">store</mat-icon>
        </button>
        <button fxHide.xs mat-icon-button (click)="logoutConfirmation()" style="margin-right: 30px">
          <mat-icon matTooltip="Αποσύνδεση">exit_to_app</mat-icon>
        </button>
      </div>

  <!--    </div>-->
      <div fxLayout="row" fxHide.gt-sm>
        <mat-icon style="cursor: pointer;" [matMenuTriggerFor]="menu" #menuTrigger="matMenuTrigger">menu</mat-icon>
        <mat-menu #menu="matMenu">
          <span (mouseleave)="menuTrigger.closeMenu()">
            <!-- <a routerLinkActive="active" routerLink="/home/main"><button fxLayoutAlign="center" mat-menu-item>Αρχική</button></a> -->
            <a routerLinkActive="active" routerLink="/home/dashboard"> <button fxLayoutAlign="center" mat-menu-item>Αρχική Σελίδα</button></a>
            <mat-divider></mat-divider>
            <a routerLinkActive="active" routerLink="/home/new-print"> <button fxLayoutAlign="center" mat-menu-item>Νέα Εκτύπωση</button></a>
            <mat-divider></mat-divider>
            <a routerLinkActive="active" routerLink="/home/ready-order"> <button fxLayoutAlign="center" mat-menu-item>Οι Εκτυπώσεις Μου</button></a>
            <mat-divider></mat-divider>
            <a routerLinkActive="active" routerLink="/home/pending-order">
              <button fxLayoutAlign="center" mat-menu-item>
                Εκκρεμείς Εκτυπώσεις <div *ngIf="pending_order_counter > 0" class="burger-pending-badge">{{pending_order_counter}}</div>
              </button>
            </a>
            <mat-divider></mat-divider>
            <a routerLinkActive="active" routerLink="/home/profile"> <button fxLayoutAlign="center" mat-menu-item>Προφίλ</button></a>
            <mat-divider></mat-divider>
            <button mat-menu-item (click)="showContactData()" fxLayoutAlign="center">Στοιχεία Επικοινωνίας</button>
            <mat-divider></mat-divider>
            <button mat-menu-item (click)="logout()" fxLayoutAlign="center">Αποσύνδεση</button>
          </span>
        </mat-menu>
      </div>
    </mat-toolbar-row>
  </mat-toolbar>

  <div class="main">
    <router-outlet></router-outlet>
  </div>
 <!-- <app-footer></app-footer> -->
</div>

<!--[ngStyle.lt-md]="{'position': 'fixed', 'bottom': '0'}"-->
