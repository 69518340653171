import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor(public httpClient: HttpClient, private router: Router) {
  }

  getRequest(endpoint: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.httpClient.get(`${endpoint}`).subscribe(data => {
        resolve(data);
      }, error => {
        reject(error);
      });
    });
  }

  postRequest(endpoint, model): Promise<any> {
    return new Promise((resolve, reject) => {
      this.httpClient.post(`${endpoint}`, model).subscribe(data => {
        resolve(data);
      }, error => {
        reject(error);
      });
    });
  }

  postFilesRequest(endpoint, model): Promise<any> {
    return new Promise((resolve, reject) => {
      this.httpClient.post(`${endpoint}`, model).subscribe(data => {
        resolve(data);
      }, error => {
        reject(error);
      });
    });
  }

  putRequest(endpoint, model): Promise<any> {
    return new Promise((resolve, reject) => {
      this.httpClient.put(`${endpoint}`, model).subscribe(data => {
        resolve(data);
      }, error => {
        reject(error);
      });
    });
  }

  deleteRequest(endpoint): Promise<any> {
    return new Promise((resolve, reject) => {
      this.httpClient.delete(`${endpoint}`).subscribe(data => {
        resolve(data);
      }, error => {
        reject(error);
      });
    });
  }
}
