
<div class="main-div">
    <mat-toolbar class="mat-toolbar" *ngIf="guest">
      <mat-toolbar-row fxLayoutAlign="space-between center">
        <div [ngStyle.lt-sm]="{'font-size':'small'}">
          <app-header></app-header>
        </div>

        <div fxLayout="row" fxHide.md>
          <button (click)="login()"class="text-btn" fxHide.xs mat-button>
            <span>
              Είσοδος
            </span>
          </button>
          <button (click)="register()" class="text-btn" fxHide.xs mat-button>
            <span>
                Δημιουργία λογαριασμού
            </span>
          </button>
          <button (click)="showContactData()" class="text-btn" fxHide.xs mat-button>
            <span>
                Στοιχεία Επικοινωνίας
            </span>
          </button>
        </div>

        <div fxLayout="row" fxHide.gt-md>
          <mat-icon style="cursor: pointer;" [matMenuTriggerFor]="menu" #menuTrigger="matMenuTrigger">menu</mat-icon>
          <mat-menu #menu="matMenu">
            <span (mouseleave)="menuTrigger.closeMenu()">
            <!-- <a routerLinkActive="active" routerLink="/home/main"><button fxLayoutAlign="center" mat-menu-item>Αρχική</button></a> -->
            <a (click)="login()"> <button fxLayoutAlign="center" mat-menu-item>Είσοδος</button></a>
            <a (click)="register()"> <button fxLayoutAlign="center" mat-menu-item>Δημιουργία λογαριασμού</button></a>
              <a (click)="showContactData()"> <button fxLayoutAlign="center" mat-menu-item>Στοιχεία Επικοινωνίας</button></a>
            </span>
          </mat-menu>
        </div>
      </mat-toolbar-row>
    </mat-toolbar>

    <app-nav-bar *ngIf="!guest"></app-nav-bar>

    <div class="main">
      <app-new-print (logged)="loginUser()" [is_guest]="true"></app-new-print>
    </div>

</div>
