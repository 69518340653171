import { CommonService } from 'src/app/services/common.service';
import { LogoutDialogComponent } from './../logout-dialog/logout-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { PrintService } from './../../services/print.service';
import {Component, OnInit} from '@angular/core';
import {UserService} from '../../services/users/user.service';
import { Router, NavigationEnd } from '@angular/router';
import {ContactDialogComponent} from '../contact-dialog/contact-dialog.component';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent implements OnInit {
  user: any;
  ready_order_counter = 0;
  pending_order_counter = 0;
  new_announcements_counter = 0;

  constructor(private userService: UserService,
              private router: Router,
              private printService: PrintService,
              private commonService: CommonService,
              private contactDialog: MatDialog,
              private dialog: MatDialog) {
    // this.user = this.userService.validateUser();
    // if (!this.user) {
    //   this.router.navigate(['/login']);
    // }

    //get orders data to inform badges when route changes
    this.router.events.subscribe(async (ev) => {
      if (ev instanceof NavigationEnd) {
        await this.syncOrders();
        await this.syncAnnouncements();
      }
    });
  }

  async ngOnInit(): Promise<void> {
    this.syncOrders();
    this.syncAnnouncements();
  }

  async syncAnnouncements() {
    try {

      let user = await this.userService.getLoggedUser();

      if (user) {
        this.new_announcements_counter = await this.commonService.getRequest('user/new-announcements')
      }

    } catch (error) {
      this.new_announcements_counter = 0;
    }
  }

  async syncOrders() {

    try {

      let user = await this.userService.getLoggedUser();

      if (user) {
        this.pending_order_counter = await this.printService.getPendingCounter();
      }

    } catch (error) {
      this.pending_order_counter = 0;
    }

  }

  async logout() {
    await this.userService.userLogout();
  }

  async logoutConfirmation() {
    const dialog = this.dialog.open(LogoutDialogComponent,  );

    dialog.afterClosed().subscribe(result => {
      if (result) {
        this.logout();
      }
    });
  }
  showContactData() {
    const contactDialog = this.contactDialog.open(ContactDialogComponent);
  }

}
