import { Component, OnInit } from '@angular/core';
import { ProgressSpinnerDialogService } from 'src/app/services/progress-spinner-dialog.service';
import {PrintService} from '../../services/print.service';

@Component({
  selector: 'app-ready-order-details',
  templateUrl: './ready-order-details.component.html',
  styleUrls: ['./ready-order-details.component.scss']
})
export class ReadyOrderDetailsComponent implements OnInit {
  userOrders = [];
  dataLoaded: boolean;
  searchData = [];

  constructor(private printService: PrintService, private progressSpinnerDialogService: ProgressSpinnerDialogService) {
    this.dataLoaded = false;
  }

  async ngOnInit() {
    try {
      this.progressSpinnerDialogService.show();
      const result = await this.printService.getCompletedOrders();

      for (const item of result) {
        this.userOrders.push(item);
      }
      this.progressSpinnerDialogService.end();

      this.dataLoaded = true;
    } catch (e) {
      this.progressSpinnerDialogService.end();

      console.log(e);
    }
  }

}
