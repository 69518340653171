import { ProgressSpinnerDialogComponent } from './../progress-spinner-dialog/progress-spinner-dialog.component';
import {Component, HostListener, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {UserService} from '../../services/users/user.service';
import { ProgressSpinnerDialogService } from 'src/app/services/progress-spinner-dialog.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
 
export class LoginComponent implements OnInit {
  username: string;
  password: string;
  user: any;
  hide: boolean;
  error: boolean;
  loading: boolean = false;

  @HostListener('document:keypress', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) { 
    if (event.key === "Enter") {
      if (this.inputsValid()) {
        this.userLogin();
      }
    }
  }

  constructor(public router: Router,
    private userService: UserService, 
    private progressSpinnerDialogService: ProgressSpinnerDialogService) {
    this.hide = true;
    this.username = '';
    this.password = '';
    // this.error = false;

  }

  async ngOnInit(): Promise<void> {

    if (navigator.onLine) {
      let user = await this.userService.checkLoggedUser();
 
      if (user) {
        this.router.navigateByUrl("/home/main")
      }
    }
  }

  inputsValid(){
    if (this.username === '' || this.password === '') {
      return false;
    }else {
    return true;
    }
  }

  async userLogin() {
    try {
      this.progressSpinnerDialogService.show();
      await this.userService.userLogin(this.username, this.password);
      this.progressSpinnerDialogService.end();
    } catch (e) {
      console.log(e);
      this.progressSpinnerDialogService.end();
    }
  }

}
