import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';
import { ProgressSpinnerDialogService } from 'src/app/services/progress-spinner-dialog.service';
import { ToastMessagesService } from 'src/app/services/toast-messages.service';
import { UserService } from 'src/app/services/users/user.service';

@Component({
  selector: 'app-login-dialog',
  templateUrl: './login-dialog.component.html',
  styleUrls: ['./login-dialog.component.scss']
})
export class LoginDialogComponent implements OnInit {

  username: string;
  password: string;
  user: any;
  hide: boolean = true;
  error: boolean;
  loading: boolean = false;
  quick = false;

  @HostListener('document:keypress', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === "Enter") {
      if (this.inputsValid()) {
        this.userLogin();
      }
    }
  }

  constructor(private toastMessagesService: ToastMessagesService,
    private commonService: CommonService,
    public dialogRef: MatDialogRef<LoginDialogComponent>,
    public router: Router,
    private userService: UserService,
    private progressSpinnerDialogService: ProgressSpinnerDialogService,
    @Inject(MAT_DIALOG_DATA) data) {

      if (data) {
        this.quick = data.quick;
      }
    }

  ngOnInit(): void {
  }

  async userLogin() {
    try {
      this.progressSpinnerDialogService.show();
      await this.userService.userLogin(this.username, this.password, !this.quick);

      if (this.quick) {
        this.closeDialog({ quick_login: true })
      } else {
        this.progressSpinnerDialogService.end();
      }

    } catch (e) {
      console.log(e);
      this.progressSpinnerDialogService.end();
    }
  }

  inputsValid(){
    if (!this.username || !this.password) {
      return false;
    }else {
      return true;
    }
  }

  forgotPassword() {
    this.closeDialog('forgot-password');
  }

  closeDialog(data: any = false) {
    this.dialogRef.close(data);
  }
}
