import { RegisterDialogComponent } from '../register-dialog/register-dialog.component';
import { LoginDialogComponent } from '../login-dialog/login-dialog.component';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ProgressSpinnerDialogService } from 'src/app/services/progress-spinner-dialog.service';
import { ForgotPasswordDialogComponent } from '../forgot-password-dialog/forgot-password-dialog.component';
import { UserService } from 'src/app/services/users/user.service';
import {CommonService} from '../../services/common.service';
import {ContactDialogComponent} from '../contact-dialog/contact-dialog.component';

@Component({
  selector: 'app-anonymous-print',
  templateUrl: './anonymous-print.component.html',
  styleUrls: ['./anonymous-print.component.scss']
})
export class AnonymousPrintComponent implements OnInit {

  guest = true;
  constructor(private loginDialog: MatDialog,
              private forgotDialog: MatDialog,
              private registerDialog: MatDialog,
              private contactDialog: MatDialog,
              private router: Router,
              private userService: UserService,
              private commonService: CommonService,
              private progressSpinnerDialogService: ProgressSpinnerDialogService,) { }

  contactData: any;

  async ngOnInit(): Promise<void> {
    if (navigator.onLine) {

      try {
        const user = await this.userService.validateUser(false);

        if (user) {
          this.router.navigateByUrl("/home/main");
        } else {
          this.userService.userLogout(false, false);
        }

      } catch (error) {
        console.log(error);
        this.userService.userLogout(false, false);
      }

    }
    this.contactData = await this.commonService.getRequest('communication');
  }

  async login() {

    const loginDialog = this.loginDialog.open(LoginDialogComponent, {width: '400px' });

    loginDialog.afterClosed().subscribe(result => {
      if (result === 'forgot-password') {
        this.forgotPassword();
      }
    });
  }

  async register() {
    const registerDialog = this.registerDialog.open(RegisterDialogComponent, { width: '50vw'});

    registerDialog.afterClosed().subscribe(result => {

    });

  }

  async forgotPassword() {
    const forgotDialog = this.forgotDialog.open(ForgotPasswordDialogComponent,  );

    forgotDialog.afterClosed().subscribe(result => {

    });
  }

  loginUser() {
    this.guest = false;
    this.progressSpinnerDialogService.end();
  }

  showContactData() {
    const contactDialog = this.contactDialog.open(ContactDialogComponent);
  }
}
